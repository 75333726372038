import ApiService from './base/ApiService';
import {
  IBlogAuthor,
  IBlogAuthorUpdate,
} from '@/store/modules/BlogAuthorModule';

export default class BlogAuthorService extends ApiService<
  IBlogAuthor,
  IBlogAuthorUpdate
> {
  constructor() {
    super('blog-authors');
  }
}
