import OrderService from '@/services/api/OrderService';
import { getModule, Module } from 'vuex-module-decorators';
import store from '..';
import { IEntityService, ModuleBase } from '../ModuleBase';
import { IUser } from './UserModule';

export interface IOrder {
  id: number;
  user: IUser;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface IOrderUpdate {
  id: number;
}

export interface IOrderState {
  entity: IOrder | null;
  entities: IOrder[];
}

@Module({ dynamic: true, store, name: 'orders', namespaced: true })
export class OrderModule
  extends ModuleBase<IOrder, IOrderUpdate>
  implements IOrderState
{
  entity: IOrder | null = null;
  entities: IOrder[] = [];

  getServiceInstance(): IEntityService<IOrder, IOrderUpdate> {
    return new OrderService();
  }

  convertToUpdate(entity: IOrder): IOrderUpdate {
    return {
      id: entity.id,
    } as IOrderUpdate;
  }
}

export default getModule(OrderModule, store);
