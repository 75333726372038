
































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import StatisticModule, {
  IJobOfferStatistic,
} from '@/store/modules/StatisticModule';
import AuditModule, { IAudit } from '@/store/modules/AuditModule';
import AuditLogTableDensed from '@/components/AuditLogTableDensed.vue';
//import VueApexCharts from 'vue-apexcharts';

//Vue.use(VueApexCharts);

@Component({
  components: {
    AuditLogTableDensed,
    //apexchart: VueApexCharts,
  },
})
export default class Dashboard extends Vue {
  jobOfferStatsTab = null;
  jobOfferStats: IJobOfferStatistic | null = null;
  jobOfferChartDataAllTime: { name: string; data: number }[] = [];
  jobOfferChartData7days: { name: string; data: number }[] = [];
  jobOfferChartData30days: { name: string; data: number }[] = [];

  latestLogs: IAudit[] | null = null;

  // donutChartOptions = {
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         chart: {
  //           width: 200,
  //         },
  //         legend: {
  //           position: 'bottom',
  //         },
  //       },
  //     },
  //   ],
  // };

  async mounted(): Promise<void> {
    await StatisticModule.loadJobOfferStatistic();
    this.jobOfferStats = StatisticModule.jobOfferStatistic;

    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 3);
    this.latestLogs = await AuditModule.loadAllFiltered({
      fromDate: daysAgo,
      toDate: new Date(),
    });

    this.createChartDataAllTime();
    this.createChartData7days();
    this.createChartData30days();
  }

  createChartDataAllTime(): void {
    if (!this.jobOfferStats) {
      return;
    }
    const stats = this.jobOfferStats;
    this.jobOfferChartDataAllTime = [
      { name: 'Created', data: stats.total },
      { name: 'External', data: stats.newCreatedExternal },
      { name: 'Internal', data: stats.newCreatedInternal },
      { name: 'Suspended', data: stats.suspended },
      { name: 'Highlighted', data: stats.highlighted },
      { name: 'Renew Cancelled', data: stats.renewCancelled },
      // { name: 'From RemoteOk', data: stats.fromRemoteOk },
      { name: 'Approved', data: stats.approved },
      { name: 'Not Approved', data: stats.notApproved },
      { name: 'Deleted', data: stats.deleted },
    ];
  }

  createChartData7days(): void {
    if (!this.jobOfferStats) {
      return;
    }
    const stats = this.jobOfferStats;
    this.jobOfferChartData7days = [
      { name: 'Created', data: stats.last7days_newCreated },
      { name: 'External', data: stats.last7days_newCreatedExternal },
      { name: 'Internal', data: stats.last7days_newCreatedInternal },
      { name: 'Suspended', data: stats.last7days_suspended },
      { name: 'Highlighted', data: stats.last7days_highlighted },
      { name: 'Renew Cancelled', data: stats.last7days_renewCancelled },
      // { name: 'From RemoteOk', data: stats.last7days_fromRemoteOk },
      { name: 'Approved', data: stats.last7days_approved },
      { name: 'Not Approved', data: stats.last7days_notApproved },
      { name: 'Deleted', data: stats.last7days_deleted },
    ];
  }

  createChartData30days(): void {
    if (!this.jobOfferStats) {
      return;
    }
    const stats = this.jobOfferStats;
    this.jobOfferChartData30days = [
      { name: 'Created', data: stats.last30days_newCreated },
      { name: 'External', data: stats.last30days_newCreatedExternal },
      { name: 'Internal', data: stats.last30days_newCreatedInternal },
      { name: 'Suspended', data: stats.last30days_suspended },
      { name: 'Highlighted', data: stats.last30days_highlighted },
      { name: 'Renew Cancelled', data: stats.last30days_renewCancelled },
      // { name: 'From RemoteOk', data: stats.last30days_fromRemoteOk },
      { name: 'Approved', data: stats.last30days_approved },
      { name: 'Not Approved', data: stats.last30days_notApproved },
      { name: 'Deleted', data: stats.last30days_deleted },
    ];
  }
}
