const imageFiguresPlugin = (md: any, config: any) => {
  md.renderer.rules.image = function (
    tokens: any[],
    idx: number,
    options: any,
    env: any,
    self: any,
  ) {
    config = config || {};

    const token = tokens[idx];
    const captionToken = tokens[idx + 1];

    let hasCaption = false;

    const srcIndex = token.attrIndex('src');
    //const imgAltIndex = token.attrIndex('alt');
    const titleIndex = token.attrIndex('title');
    let url = token.attrs[srcIndex][1];
    const title = token.attrs[titleIndex][1];
    const imgAlt = token.content;
    let caption = '';

    if (url.startsWith('/')) {
      url = `${process.env.VUE_APP_APP_URL}${url}`;
    }
    if (
      !url.endsWith('.jpeg') &&
      !url.endsWith('.jpg') &&
      !url.endsWith('.png') &&
      !url.endsWith('.webp')
    ) {
      url = url + '.jpg';
    }

    if (captionToken) {
      let capContent: string = captionToken.content.toString();
      if (capContent.length > 1 && capContent.startsWith('{')) {
        hasCaption = true;
        if (capContent.endsWith('}')) {
          caption = capContent.substr(1, capContent.length - 2);
        } else {
          caption = capContent.substr(1);
          let nextIndex = idx + 2;
          while (capContent != '' && !capContent.endsWith('}')) {
            if (!tokens[nextIndex]) {
              capContent = '';
              break;
            }
            capContent = tokens[nextIndex].content.toString();
            tokens[nextIndex].hidden = true;
            tokens[nextIndex].content = '';

            caption += capContent;

            if (caption.endsWith('}')) {
              caption = caption.substr(0, caption.length - 1);
              break;
            }
            nextIndex++;
          }
        }
        tokens[idx + 1].hidden = true;
        tokens[idx + 1].content = '';
      }
    }

    const width = '';
    const height = '';

    if (hasCaption) {
      return `<figure>
        <img src="${url}" alt="${imgAlt}" title="${title}" width="${width}" height="${height}" style="border-radius: 10px;">
        <figcaption style="color: gray; text-align: center; font-size: small;">${caption}</figcaption>
      </figure>`;
    }
    return `<img src="${url}" alt="${imgAlt}" title="${title}" width="${width}" height="${height}">`;
  };
};

export default imageFiguresPlugin;
