













































import DataTableDialogs from '@/components/DataTable-Dialogs.vue';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import OrderModule, { IOrder, IOrderUpdate } from '@/store/modules/OrderModule';
import OrderItemModule, { IOrderItem } from '@/store/modules/OrderItemModule';

@Component({
  components: {},
})
class OrdersDataTableDialogs extends Mixins<
  DataTableDialogs<IOrder, IOrderUpdate>
>(DataTableDialogs) {
  setup(): void {
    this.entityName = 'Order';
    this.entityNamePl = 'Orders';
    this.module = OrderModule;
    this.showActions = false;
    this.showCreateButton = false;
    this.showExpand = true;

    this.addHeaders([
      { text: 'Order #', value: 'orderNumber' },
      { text: 'Amount', value: 'amount' },
      { text: 'Coupon', value: 'couponCode' },
      { text: 'Payed', value: 'isPayed' },
      { text: 'Status', value: 'paymentStatus' },
      { text: 'Date', value: 'date' },
      { text: 'User', value: 'user' },
    ]);
  }
}

@Component({
  components: {
    OrdersDataTableDialogs,
  },
})
export default class Orders extends Vue {
  loading = false;
  orderItems: IOrderItem[] = [];

  async rowExpanded(item: IOrder, isExpanded: boolean): Promise<void> {
    if (isExpanded) {
      this.loading = true;
      this.orderItems = await OrderItemModule.loadByOrderId(item.id);
      this.loading = false;
    }
  }
}
