import { Module, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { IEntityService, ModuleBase } from '../ModuleBase';
import BlogCategoryService from '@/services/api/BlogCategoryService';
import { IBlogArticle } from './BlogArticleModule';

export interface IBlogCategoryState {
  entities: IBlogCategory[];
  entity: IBlogCategory | null;
}
export interface IBlogCategory {
  id: number;
  name: string;
  slug: string;
  isAudienceNomad: boolean;
  isAudienceCompany: boolean;
  mainArticles?: IBlogArticle[];
  articles?: IBlogArticle[];
  parent: IBlogCategory;
  children: IBlogCategory[];
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IBlogCategoryUpdate {
  name: string;
  slug: string;
  isAudienceNomad: boolean;
  isAudienceCompany: boolean;
  mainArticles?: IBlogArticle[];
  articles?: IBlogArticle[];
  parent: IBlogCategory;
  children: IBlogCategory[];
}

@Module({ dynamic: true, store, name: 'blog-categories', namespaced: true })
export class BlogCategoryModule
  extends ModuleBase<IBlogCategory, IBlogCategoryUpdate>
  implements IBlogCategoryState
{
  entity: IBlogCategory | null = null;
  entities: IBlogCategory[] = [];

  getServiceInstance(): IEntityService<IBlogCategory, IBlogCategoryUpdate> {
    return new BlogCategoryService();
  }

  convertToUpdate(entity: IBlogCategory): IBlogCategoryUpdate {
    return {
      name: entity.name,
      slug: entity.slug,
      isAudienceNomad: entity.isAudienceNomad,
      isAudienceCompany: entity.isAudienceCompany,
      mainArticles: entity.mainArticles,
      articles: entity.articles,
      parent: entity.parent,
      children: entity.children,
    } as IBlogCategoryUpdate;
  }
}

export default getModule(BlogCategoryModule, store);
