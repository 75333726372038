





















































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import SubscriptionModule, {
  ISubscription, ISubscriptionUpdate,
} from '@/store/modules/SubscriptionModule';
import moment from 'moment';
import { JobType, Profession } from '@/store/modules/JobOfferModule';
import DataTableDialogs from '@/components/DataTable-Dialogs.vue';

@Component({
  components: {},
})
class SubscriptionDataTableDialogs extends Mixins<
  DataTableDialogs<ISubscription, ISubscriptionUpdate>
>(DataTableDialogs) {
  setup(): void {
    this.entityName = 'Subscription';
    this.entityNamePl = 'Subscriptions';
    this.module = SubscriptionModule;

    this.addHeaders([
      { text: 'Email', value: 'email' },
      { text: 'Active', value: 'isActive' },
      { text: 'Email Confirmed', value: 'isEmailConfirmed' },
      { text: 'Professions', value: 'professions' },
      { text: 'Job Types', value: 'jobTypes' },
      { text: 'User', value: 'user' },
    ]);
  }
}

@Component({
  components: {
    SubscriptionDataTableDialogs,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class Users extends Vue {
  allProfessions = Object.values(Profession);
  allJobTypes = Object.values(JobType);
}
