export default class LocalStorageHelper {
  static clear(): void {
    localStorage.clear();
  }

  static getItem(key: keyof typeof StorageKeys, keySuffix = ''): string | null {
    const storageKey = this.makeKey(key, keySuffix);
    return localStorage.getItem(storageKey);
  }

  static getItemInt(
    key: keyof typeof StorageKeys,
    keySuffix = '',
  ): number | null {
    const value = this.getItem(key, keySuffix);
    if (!value) {
      return null;
    }
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      return null;
    }
    return parsed;
  }

  static getItemJson(
    key: keyof typeof StorageKeys,
    keySuffix = '',
  ): unknown | null {
    const storageKey = this.makeKey(key, keySuffix);
    const value = localStorage.getItem(storageKey);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  }

  static removeItem(key: keyof typeof StorageKeys, keySuffix = ''): void {
    const storageKey = this.makeKey(key, keySuffix);
    return localStorage.removeItem(storageKey);
  }

  static setItem(
    key: keyof typeof StorageKeys,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: string | any,
    keySuffix = '',
  ): void {
    const storageKey = this.makeKey(key, keySuffix);
    if (typeof value == 'string') {
      return localStorage.setItem(storageKey, value);
    }
    return localStorage.setItem(storageKey, value.toString());
  }

  static setItemJson(
    key: keyof typeof StorageKeys,
    decoded: unknown,
    keySuffix = '',
  ): void {
    this.setItem(key, keySuffix, JSON.stringify(decoded));
  }

  private static makeKey(key: unknown, keySuffix: unknown) {
    const suffix = keySuffix ? `_${keySuffix}` : '';
    return `${key}${suffix}`;
  }
}

export enum StorageKeys {
  token = 'token',
  refresh_token = 'refresh_token',
}
