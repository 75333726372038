export class ApiServiceOptions {
  baseUrl = '/api/v1/';
  useAuth = true;

  constructor(baseUrl?: string, useAuth?: boolean) {
    if (baseUrl !== undefined) {
      this.baseUrl = baseUrl;
    }
    if (useAuth !== undefined) {
      this.useAuth = useAuth;
    }
  }
}
