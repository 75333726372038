




































































































import DataTableDialogs from '@/components/DataTable-Dialogs.vue';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import AuditModule, {
  IAudit,
  IAuditUpdate,
  IAuditLoadAllFilter,
  auditLevels,
} from '@/store/modules/AuditModule';
import moment from 'moment';


@Component({
  components: {},
})
class AuditsDataTableDialogs extends Mixins<
  DataTableDialogs<IAudit, IAuditUpdate>
>(DataTableDialogs) {
  setup(): void {
    this.entityName = 'Audit';
    this.entityNamePl = 'Audits';
    this.module = AuditModule;
    this.showActions = false;
    this.showCreateButton = false;
    this.showExpand = true;

    this.addHeaders([
      { text: '', value: 'icon' },
      { text: 'Task', value: 'task' },
      { text: 'Message', value: 'message' },
      { text: 'Level', value: 'level' },
      { text: 'Local Date', value: 'createdAt' },
    ]);
  }

  //override
  itemRowBackground(item: IAudit): string {
    return auditLevels[item.level].rowColor;
  }
}

@Component({
  components: {
    AuditsDataTableDialogs,
  },
  filters: {
    toUtcTime(date?: Date) {
      return date && moment.utc(date).format('DD. MMM YYYY HH:mm');
    },
  },
})
export default class Audits extends Vue {
  loading = false;
  levelInfo = auditLevels;
  entities: IAudit[] = [];

  datePickerMenu = false;
  dates: Date[] | null = null;
  selectedLevels: number[] = [];

  auditLevelsSelect = [
    { level: 0, name: 'Emergency' },
    { level: 1, name: 'Alert' },
    { level: 2, name: 'Critical' },
    { level: 3, name: 'Error' },
    { level: 4, name: 'Warning' },
    { level: 5, name: 'Notice' },
    { level: 6, name: 'Info' },
    { level: 7, name: 'Debug' },
  ];

  filterOptions: IAuditLoadAllFilter = {
    levels: this.auditLevelsSelect.map((x) => x.level),
    fromDate: undefined,
    toDate: undefined,
  };

  async created(): Promise<void> {
    this.selectedLevels = this.auditLevelsSelect.map((x) => x.level);
  }

  get dateRangeText(): string {
    if (!this.dates || this.dates.length != 2) {
      return '';
    }

    const firstDate = new Date(this.dates[0]);
    const secondDate = new Date(this.dates[1]);

    const fromDate = firstDate < secondDate ? this.dates[0] : this.dates[1];
    const toDate = firstDate > secondDate ? this.dates[0] : this.dates[1];

    return `${fromDate} - ${toDate}`;
  }

  @Watch('dates')
  async onDatesChange(newValue: Date[] | null): Promise<void> {
    if (!newValue || newValue.length != 2) {
      this.filterOptions.fromDate = undefined;
      this.filterOptions.toDate = undefined;
      return;
    }
    const firstDate = new Date(newValue[0]);
    const secondDate = new Date(newValue[1]);

    const fromDate = firstDate <= secondDate ? firstDate : secondDate;
    const toDate = firstDate > secondDate ? firstDate : secondDate;

    toDate.setDate(toDate.getDate() + 1); //check for the end of the day

    this.filterOptions.fromDate = fromDate;
    this.filterOptions.toDate = toDate;

    await AuditModule.loadAllFiltered(this.filterOptions);
    this.entities = AuditModule.entities || [];
  }

  @Watch('selectedLevels')
  async onSelectedLevelsChange(newValue: number[]): Promise<void> {
    this.filterOptions.levels = newValue;

    await AuditModule.loadAllFiltered(this.filterOptions);
    this.entities = AuditModule.entities || [];
  }
}
