import BaseApiService from '@/services/api/base/BaseApiService';
import { IAuthResponse } from '@/store/modules/AuthModule';
import { AxiosPromise, AxiosResponse } from 'axios';

export default class AuthService {
  static login(
    username: string,
    password: string,
  ): AxiosPromise<IAuthResponse> {
    return BaseApiService.getInstance({
      baseUrl: '/api/v1/',
      useAuth: false,
    }).post<IAuthResponse>('/auth/login', {
      username,
      password,
    });
  }

  static refreshToken(
    refresh_token: string,
  ): Promise<AxiosResponse<IAuthResponse>> {
    return BaseApiService.getInstance({
      baseUrl: '/api/v1/',
      useAuth: false,
    }).post<IAuthResponse>('/auth/token/refresh', {
      refresh_token,
    });
  }
}
