import AuditService from '@/services/api/AuditService';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '..';
import { IEntityService, ModuleBase } from '../ModuleBase';

export enum AuditLevel {
  emerg = 0,
  alert = 1,
  crit = 2,
  error = 3,
  warning = 4,
  notice = 5,
  info = 6,
  debug = 7,
}

export interface IAudit {
  id: number;
  task: string;
  message: string;
  level: AuditLevel;
  jsonData?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface IAuditUpdate {
  id: number;
}

export interface IAuditState {
  entity: IAudit | null;
  entities: IAudit[];
}

export interface IAuditLoadAllFilter {
  levels?: AuditLevel[];
  fromDate?: Date;
  toDate?: Date;
}

export const auditLevels = [
  {
    level: 0,
    name: 'Emergency',
    color: 'red darken-4',
    textColor: 'white',
    icon: 'local_fire_department',
    rowColor: 'red lighten-2',
  },
  {
    level: 1,
    name: 'Alert',
    color: 'red darken-3',
    textColor: 'white',
    icon: 'dangerous',
    rowColor: 'red lighten-3',
  },
  {
    level: 2,
    name: 'Critical',
    color: 'red darken-2',
    textColor: 'white',
    icon: 'report_gmailerrorred',
    rowColor: 'red lighten-4',
  },
  {
    level: 3,
    name: 'Error',
    color: 'red darken-1',
    textColor: 'white',
    icon: 'error_outline',
    rowColor: 'red lighten-5',
  },
  {
    level: 4,
    name: 'Warning',
    color: 'yellow darken-1',
    textColor: 'dark',
    icon: 'warning',
    rowColor: 'yellow lighten-5',
  },
  {
    level: 5,
    name: 'Notice',
    color: 'teal lighten-3',
    textColor: 'dark',
    icon: 'lightbulb',
    rowColor: 'teal lighten-5',
  },
  {
    level: 6,
    name: 'Info',
    color: 'light-blue lighten-2',
    textColor: 'white',
    icon: 'info',
    rowColor: 'blue lighten-5',
  },
  {
    level: 7,
    name: 'Debug',
    color: 'orange lighten-3',
    textColor: 'white',
    icon: 'bug_report',
    rowColor: 'orange lighten-5',
  },
];

export interface ICronGroup {
  task: string;
  info: ICronInfo;
  errorsPast7days: IAudit[];
  errorCount: number;
  latest: IAudit;
  all: IAudit[];
}

export interface ICronInfo {
  name: string;
  task: string;
  description: string;
  frequency: string;
}

export const cronInfo: ICronInfo[] = [
  {
    name: 'RemoteOk Import',
    task: 'importRemoteOk',
    description: 'Importer for Remote Ok job offers',
    frequency: 'Every day at 2am',
  },
  {
    name: 'Renew Job Offer Subscriptions',
    task: 'renewSubscriptions',
    description: 'Renews expired subscriptions if not cancelled',
    frequency: 'Every day at Midnight',
  },
  {
    name: 'Send Weekly Newsletter',
    task: 'sendWeeklyNewsletter',
    description: 'Send weekly newsletter to subscribed users',
    frequency: 'Every week',
  },
  {
    name: 'Delete Unconfirmed Subscriptions',
    task: 'deleteUnconfirmedSubscriptions',
    description:
      'Delete Newsletter subscriptions without confirmed Email that are older than 7 days',
    frequency: 'Every week',
  },
  {
    name: 'Weekly Statistic Mail (Admin)',
    task: 'sendWeeklyStatisticMailAdmin',
    description: 'Sending a weekly mail to Admin with statistics',
    frequency: 'Every week',
  },
  {
    name: 'Clean Up Old Audits',
    task: 'cleanUpOldAudits',
    description:
      'Delete all audits logged in the DB that are older than 120 days',
    frequency: 'Every week',
  },
];

@Module({ dynamic: true, store, name: 'audits', namespaced: true })
export class AuditModule
  extends ModuleBase<IAudit, IAuditUpdate>
  implements IAuditState
{
  entity: IAudit | null = null;
  entities: IAudit[] = [];

  getServiceInstance(): IEntityService<IAudit, IAuditUpdate> {
    return new AuditService();
  }

  convertToUpdate(entity: IAudit): IAuditUpdate {
    return {
      id: entity.id,
    } as IAuditUpdate;
  }

  @Mutation
  loadAllFilteredMutation(entities: IAudit[]): void {
    this.entities = entities;
  }

  @Action({ commit: 'loadAllFilteredMutation' })
  async loadAllFiltered(filter: IAuditLoadAllFilter): Promise<IAudit[]> {
    const response = await (this.service as AuditService).getAllFiltered(
      filter,
    );
    return response;
  }

  @Mutation
  loadFilteredByTagsMutation(entities: IAudit[]): void {
    this.entities = entities;
  }

  @Action({ commit: 'loadFilteredByTagsMutation' })
  async loadFilteredByTags(tags: string[]): Promise<IAudit[]> {
    const response = await (this.service as AuditService).getFilteredByTags(
      tags,
    );
    return response;
  }
}

export default getModule(AuditModule, store);
