

















































































































































































































































import { ICompany } from '@/store/modules/CompanyModule';
import JobOfferModule, {
  IJobOffer,
  JobType,
  Profession,
  RegionRestriction,
} from '@/store/modules/JobOfferModule';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import VueTrix from 'vue-trix';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    VueTrix,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class JobOfferEdit extends Vue {
  allProfessions = Object.values(Profession);
  allJobTypes = Object.values(JobType);
  allRegions = Object.values(RegionRestriction);

  loading = false;
  hasChanges = false;
  loadingSaving = false;
  jobOffer: IJobOffer | null = null;
  isEditSlug = false;
  breadcrumbs = [
    {
      text: 'Dashboard',
      disabled: false,
      href: '/',
    },
    {
      text: 'Job Offers',
      disabled: false,
      href: '/job-offers',
    },
    {
      text: 'Edit Job Offer',
      disabled: true,
    },
  ];
  tab = null;
  snackbar = {
    show: false,
    text: '',
    success: true,
  };

  @Watch('jobOffer', { deep: true })
  changeJobOffer(newJobOffer: IJobOffer, oldJobOffer: IJobOffer) {
    if (oldJobOffer == null) { //skip first assignment
      return;
    }
    this.hasChanges = true;
  }

  async mounted(): Promise<void> {
    const jobOfferId = parseInt(this.$route.params.id);
    await JobOfferModule.load(jobOfferId);

    this.jobOffer = JobOfferModule.entity;

    const lastBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
    if (lastBreadcrumb) {
      lastBreadcrumb.text = this.jobOffer?.title || 'Edit Job Offer';
    }

    this.loading = false;
  }

  beforeRouteLeave(to: unknown, from: unknown, next: any) {
    if (!this.hasChanges) {
      next();
      return;
    }
    const answer = window.confirm(
      'Do you really want to leave? you have unsaved changes!'
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  }

  async applyChanges(callback: { (): void } | null = null): Promise<void> {
    this.loadingSaving = true;
    if (!this.jobOffer) {
      this.loadingSaving = false;
      console.error('Job Offer not defined!');
      return;
    }
    await JobOfferModule.update(this.jobOffer)
      .then(() => {
        this.loadingSaving = false;
        if (callback && typeof callback == 'function') {
          callback();
        } else {
          this.showSnackbar('Job Offer updated.');
        }
      })
      .catch((error) => {
        this.loadingSaving = false;
        console.error(error);
        this.showSnackbar('Error: Job Offer could not be updated!', false);
      });
  }

  async saveExit(): Promise<void> {
    this.applyChanges(() => {
      this.$router.push({ name: 'Job Offers' });
    });
  }

  showSnackbar(text: string, success = true): void {
    this.snackbar.show = true;
    this.snackbar.text = text;
    this.snackbar.success = success;
  }

  editCompany(company: ICompany): void {
    this.$router.push({
      name: 'Edit Company',
      params: { id: company.id.toString() },
    });
  }

  cancel(): void {
    this.$router.push({ name: 'Job Offers' });
  }

  async approveJobOffer(): Promise<void> {
    if (this.jobOffer) {
      this.loadingSaving = true;
      await JobOfferModule.approveJobOffer(this.jobOffer)
        .then((jobOffer: IJobOffer) => {
          if (this.jobOffer && jobOffer) {
            this.jobOffer.isApproved = jobOffer.isApproved;
            this.jobOffer.approvedAt = jobOffer.approvedAt;
            this.showSnackbar('Job Offer approved!');
          }
        })
        .finally(() => {
          this.loadingSaving = false;
        });
    }
  }
}
