var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('users-data-table-dialogs',{scopedSlots:_vm._u([{key:"form-create",fn:function(ref){
var item = ref.item;
var rules = ref.rules;
var editedIndex = ref.editedIndex;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":[rules.required, rules.email],"label":"Email"},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":[rules.required],"label":"Full name"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[rules.required],"label":"Password","type":"password"},model:{value:(item.password),callback:function ($$v) {_vm.$set(item, "password", $$v)},expression:"item.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[
                rules.required,
                _vm.localRules.passwordCompare(
                  item.passwordRepeat,
                  item.password
                ) ],"label":"Repeat Password","type":"password"},model:{value:(item.passwordRepeat),callback:function ($$v) {_vm.$set(item, "passwordRepeat", $$v)},expression:"item.passwordRepeat"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":"User is active"},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":"E-mail Confirmed"},scopedSlots:_vm._u([(editedIndex == -1)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("info")])]}}],null,true)},[_vm._v(" If not checked an confirmation email will be sent the user. ")])]},proxy:true}:null],null,true),model:{value:(item.isEmailConfirmed),callback:function ($$v) {_vm.$set(item, "isEmailConfirmed", $$v)},expression:"item.isEmailConfirmed"}})],1)],1)],1)]}},{key:"item.isActive",fn:function(ref){
              var item = ref.item;
return [(!item.isActive)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("remove_circle")]):_vm._e(),(item.isActive)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.isEmailConfirmed",fn:function(ref){
              var item = ref.item;
return [(!item.isEmailConfirmed)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("hourglass_bottom")]):_vm._e(),(!item.isEmailConfirmed)?_c('div',{staticClass:"caption text--disabled"},[_vm._v(" Signed up "+_vm._s(_vm._f("fromNow")(item.createdAt))+" ")]):_vm._e(),(item.isEmailConfirmed)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("verified")]):_vm._e()]}},{key:"item.company",fn:function(ref){
              var item = ref.item;
return [(item.company)?_c('a',{on:{"click":function($event){return _vm.editCompany(item.company)}}},[_vm._v(_vm._s(item.company.name))]):_c('span',[_vm._v("-")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }