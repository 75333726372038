var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('orders-data-table-dialogs',{on:{"row-expanded-click":_vm.rowExpanded},scopedSlots:_vm._u([{key:"delete-dialog-text",fn:function(ref){
var item = ref.item;
return [_vm._v(" Order#: "+_vm._s(item.orderNumber)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalPrice)+" "+_vm._s(item.currency)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD. MMM YYYY, HH:mm'))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" ("+_vm._s(item.user.email)+") ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pt-2 pb-2",attrs:{"colspan":headers.length}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}}):_vm._e(),(!_vm.loading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-left"},[_vm._v("Unit Price")])])]),_c('tbody',_vm._l((_vm.orderItems),function(orderItem){return _c('tr',{key:orderItem.id},[_c('td',[_vm._v(_vm._s(orderItem.id))]),_c('td',[_vm._v(_vm._s(orderItem.name))]),_c('td',[_vm._v(_vm._s(orderItem.quantity))]),_c('td',[_vm._v(_vm._s(orderItem.unitPrice)+" "+_vm._s(item.currency))])])}),0)]},proxy:true}],null,true)}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }