



















import DataTableDialogs from '@/components/DataTable-Dialogs.vue';
import CompanyModule, {
  ICompany,
  ICompanyUpdate,
} from '@/store/modules/CompanyModule';
import moment from 'moment';
import { Component, Mixins, Vue } from 'vue-property-decorator';


@Component({
  components: {},
})
class CompanyDataTableDialogs extends Mixins<
  DataTableDialogs<ICompany, ICompanyUpdate>
>(DataTableDialogs) {
  setup(): void {
    this.entityName = 'Company';
    this.entityNamePl = 'Companies';
    this.module = CompanyModule;
    this.showCreateButton = false;

    this.addHeaders([
      { text: 'Logo', value: 'logo' },
      { text: 'Name', value: 'name' },
      { text: 'Approved', value: 'isApproved' },
    ]);
  }

  //override
  editItem(item: ICompany): void {
    this.$router.push({
      name: 'Edit Company',
      params: { id: item.id.toString() },
    });
  }
}

@Component({
  components: {
    CompanyDataTableDialogs,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class Companies extends Vue {}
