

































































































export interface IMarkdownImageData {
  url: string;
  title: string;
  imgAlt: string;
  caption?: string;
  width?: string;
  height?: string;
}

import { filesFilter } from '@kangc/v-md-editor/lib/utils/file';
import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
// Resources for the codemirror editor
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
// edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';

import imageFiguresPlugin from './../plugins/markdown-it/imageFiguresPlugin';
import handlebarsPreview from './../plugins/markdown-it/handlebarsPreview';

import hljs from 'highlight.js';
import { Component, ModelSync, Vue, Watch } from 'vue-property-decorator';

import UnsplashApiService, {
  IUnsplashApiListResult,
  IUnsplashImage,
  UnsplashImageOrientation,
  UnsplashSearchOptions,
} from '@/services/api/unsplash/unsplashApiService';
import VueMasonry from 'vue-masonry-css';

Vue.use(VueMasonry);

VMdEditor.Codemirror = Codemirror;

VMdEditor.use(githubTheme, {
  Hljs: hljs,
  extend(md: any) {
    // md is a markdown-it instance, you can modify the configuration here, and use plugin for syntax expansion
    md.set({}).use(imageFiguresPlugin);
    md.set({}).use(handlebarsPreview);
  },
});

VMdEditor.lang.use('en-US', enUS);

@Component({
  components: {
    vueMarkdownEditor: VMdEditor,
  },
})
export default class NwMarkdownEditor extends Vue {
  @ModelSync('value', 'input', { type: String })
  readonly valueValue!: string;

  content = '';

  toolbar = {
    image: {
      name: 'image',
      title: 'Image',
      icon: 'v-md-icon-img',
      menus: [
        {
          name: 'image-link',
          text: (editor: any): void => editor.langConfig.imageLink.toolbar,
          action(editor: any, config: any): void {
            if (config?.insertWithSize) {
              editor.execCommand('image', { width: 'auto', height: 'auto' });
            } else {
              editor.execCommand('image');
            }
          },
        },
        {
          name: 'upload-image',
          text: (editor: any): void => editor.langConfig.uploadImage.toolbar,
          action(editor: any): void {
            editor.uploadConfig = editor.uploadImgConfig;
            editor.$nextTick(async () => {
              const event = await editor.$refs.uploadFile.upload();
              const files = filesFilter(
                event.target.files,
                editor.uploadImgConfig,
              );

              editor.emitUploadImage(event, files);
            });
          },
        },
        {
          name: 'unsplash-image',
          text: 'Unsplash Image',
          action(editor: any): void {
            editor.$nextTick(async () => {
              // editor.$parent.$refs.unsplashDialog.show();
              editor.$parent.$refs.unsplashDialog.isActive = true;
            });
            // editor.insert(function (selected: string) {
            //   const prefix = '{{hbs ';
            //   const suffix = '}}';
            //   const placeholder = 'cards/signup';
            //   const content = placeholder; //selected || placeholder;

            //   return {
            //     text: `${prefix}${content}${suffix}`,
            //     selected: content,
            //   };
            // });
          },
        },
      ],
    },
    hbsSnippets: {
      title: 'Handlebars Snippets',
      icon: 'v-md-icon-tip',
      menus: [
        {
          //{{hbs cards/signup}}
          name: 'cards-signup',
          text: 'Newsletter Signup',
          action(editor: any): void {
            editor.insert(function (selected: string) {
              const prefix = '{{hbs ';
              const suffix = '}}';
              const placeholder = 'cards/signup';
              const content = placeholder; //selected || placeholder;

              return {
                text: `${prefix}${content}${suffix}`,
                selected: content,
              };
            });
          },
        },
        {
          //{{hbs cards/post-job-ad}}
          name: 'post-job-ad',
          text: 'Post Job Offer',
          action(editor: any): void {
            editor.insert(function (selected: string) {
              const prefix = '{{hbs ';
              const suffix = '}}';
              const placeholder = 'cards/post-job-ad';
              const content = placeholder; //selected || placeholder;

              return {
                text: `${prefix}${content}${suffix}`,
                selected: content,
              };
            });
          },
        },
      ],
    },
  };

  @Watch('value')
  valueChanged(newContent: string): void {
    this.content = newContent;
  }

  handleUploadImage(event: any, insertImage: any, files: any): void {
    // Get the files and upload them to the file server, then insert the corresponding content into the editor
    this.$emit('file-upload', files, (imageData?: IMarkdownImageData) => {
      if (!imageData) {
        return;
      }
      if (imageData.caption) {
        this.insertImageWithCaption(imageData);
      } else {
        insertImage(imageData);
      }
    });
  }

  loadingUnsplash = false;
  unsplashDialog = false;
  unsplashResult?: IUnsplashApiListResult<IUnsplashImage>;
  unsplashPhotos: IUnsplashImage[] = [];
  unsplashCurrentPage = 1;
  unsplashTotalPages: number | null = null;
  unsplashTotalResults: number | null = null;
  unsplashSearchString = '';
  unsplashOrientation: number | null = null;

  async loadUnsplashPhotos(): Promise<void> {
    if (!this.unsplashSearchString) {
      return;
    }
    this.loadingUnsplash = true;
    let orientation: UnsplashImageOrientation | null = null;
    if (this.unsplashOrientation) {
      //only if one is active, when both don't set
      if (this.unsplashOrientation == 0) {
        orientation = UnsplashImageOrientation.Portrait;
      } else if (this.unsplashOrientation == 1) {
        orientation = UnsplashImageOrientation.Portrait;
      } else if (this.unsplashOrientation == 2) {
        orientation = UnsplashImageOrientation.Squarish;
      }
    }
    this.unsplashResult = await new UnsplashApiService().searchPhotos(
      this.unsplashSearchString,
      {
        page: this.unsplashCurrentPage,
        per_page: 100,
        orientation,
      } as UnsplashSearchOptions,
    );
    this.unsplashTotalResults = this.unsplashResult.total;
    this.unsplashTotalPages = this.unsplashResult.total_pages;

    this.unsplashPhotos = this.unsplashResult.results;
    this.loadingUnsplash = false;
  }

  insertUnsplashImage(image: IUnsplashImage): void {
    if (!this.$refs.markdownEditor) {
      return;
    }

    let search = this.unsplashSearchString.replace(/\W+/g, '-').toLowerCase();
    if (search && search.endsWith('-')) {
      search = search.substr(0, search.length - 1);
    }

    this.insertImageWithCaption({
      url: image.urls?.regular,
      imgAlt: image.alt_description || this.unsplashSearchString,
      title: image.alt_description || this.unsplashSearchString,
      caption: `Photo by <a href="https://unsplash.com/@${image.user?.username}?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">${image.user?.name}</a> on <a href="https://unsplash.com/s/photos/${search}?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Unsplash</a>`,
    } as IMarkdownImageData);

    this.unsplashDialog = false;
  }

  insertImageWithCaption(config: IMarkdownImageData): void {
    if (!this.$refs.markdownEditor) {
      return;
    }
    const { url, imgAlt, title, caption } = config;
    (this.$refs.markdownEditor as any).insert(() => {
      const urlPlaceholder = 'https://';
      const descPlaceholder = 'Description';
      let selected: string | null = urlPlaceholder;
      let imgAltText = '';
      if (imgAlt) {
        imgAltText = ` "${imgAlt}"`;
      }

      let text = `![${title || descPlaceholder}](${
        url || urlPlaceholder
      }${imgAltText})`;

      //const style = [];
      // if (width) {
      //   style.push(`width="${width}"`);
      // }
      // if (height) {
      //   style.push(`height="${height}"`);
      // }
      // if (style.length) {
      //   text += `{{{${style.join(' ')}}}}`;
      // }

      if (caption) {
        text += `{${caption}}`;
      }

      if (url && title) {
        selected = null;
      } else if (url) {
        selected = descPlaceholder;
      } else if (title) {
        selected = urlPlaceholder;
      }

      return {
        text,
        selected,
      };
    });
  }
}
