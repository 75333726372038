

























































































































































































































































































































































import CompanyModule, { ICompany } from '@/store/modules/CompanyModule';
import { Component, Vue, Watch } from 'vue-property-decorator';
import VueTrix from 'vue-trix';

interface Event {
  text: string;
  datetime: Date;
  isNw?: boolean;
  color?: string;
  icon?: string;
}

enum EventType {
  approve,
  create,
  update,
  delete,
  emailConfirm,
  cancel,
  ended,
}

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    VueTrix,
  },
})
export default class CompanyEdit extends Vue {
  loading = false;
  hasChanges = false;
  loadingSaving = false;
  company: ICompany | null = null;
  isEditSlug = false;
  breadcrumbs = [
    {
      text: 'Dashboard',
      disabled: false,
      href: '/',
    },
    {
      text: 'Companies',
      disabled: false,
      href: '/companies',
    },
    {
      text: 'Edit Company',
      disabled: true,
    },
  ];
  tab = null;
  jobOfferHeaders = [
    { text: 'ID', value: 'id' },
    { text: 'Title', value: 'title' },
    { text: 'Profession', value: 'profession' },
    { text: 'Type', value: 'type' },
    { text: 'Meta', value: 'meta' },
  ];
  userHeaders = [
    { text: 'ID', value: 'id' },
    {
      text: 'E-Mail',
      align: 'start',
      filterable: true,
      value: 'email',
    },
    { text: 'Name', value: 'name' },
    { text: 'Meta', value: 'meta' },
  ];
  snackbar = {
    show: false,
    text: '',
    success: true,
  };
  activities: Event[] = [];

  @Watch('company', { deep: true })
  companyChanged(newCompany: ICompany, oldCompany: ICompany) {
    if (oldCompany == null) { //skip first assignment
      return;
    }
    this.hasChanges = true;
  }

  async mounted(): Promise<void> {
    const companyId = parseInt(this.$route.params.id);
    await CompanyModule.load(companyId);

    this.company = CompanyModule.entity;

    this.prepareActivies();

    const lastBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
    if (lastBreadcrumb) {
      lastBreadcrumb.text = this.company?.name || 'Edit Company';
    }

    this.loading = false;
  }

  beforeRouteLeave(to: unknown, from: unknown, next: any) {
    if (!this.hasChanges) {
      next();
      return;
    }
    const answer = window.confirm(
      'Do you really want to leave? you have unsaved changes!'
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  }

  async applyChanges(callback: { (): void } | null = null): Promise<void> {
    this.loadingSaving = true;
    if (!this.company) {
      this.loadingSaving = false;
      console.error('Company not defined!');
      return;
    }
    await CompanyModule.update(this.company)
      .then(() => {
        this.loadingSaving = false;
        if (callback && typeof callback == 'function') {
          callback();
        } else {
          this.showSnackbar('Company updated.');
        }
      })
      .catch((error) => {
        this.loadingSaving = false;
        console.error(error);
        this.showSnackbar('Error: Company could not be updated!', false);
      });
  }

  async saveExit(): Promise<void> {
    this.applyChanges(() => {
      this.$router.push({ name: 'Companies' });
    });
  }

  showSnackbar(text: string, success = true): void {
    this.snackbar.show = true;
    this.snackbar.text = text;
    this.snackbar.success = success;
  }

  cancel(): void {
    this.$router.push({ name: 'Companies' });
  }

  addActivity(text: string, type: EventType, datetime?: Date): void {
    let color = '';
    let icon = '';
    let isNw = false;
    switch (type) {
      case EventType.approve:
        color = 'green';
        icon = 'verified';
        isNw = true;
        break;
      case EventType.create:
        color = 'orange';
        icon = 'add_circle';
        break;
      case EventType.update:
        color = 'blue';
        icon = 'update';
        isNw = true;
        break;
      case EventType.delete:
        color = 'red';
        icon = 'delete';
        isNw = true;
        break;
      case EventType.emailConfirm:
        color = 'red';
        icon = 'mark_email_read';
        break;
      case EventType.cancel:
        color = 'red';
        icon = 'not_interested';
        break;
      case EventType.ended:
        color = 'red';
        icon = 'cancel';
        break;
    }
    if (datetime) {
      this.activities.push({
        text,
        datetime,
        color,
        icon,
        isNw,
      });
    }
  }

  async approveCompany(): Promise<void> {
    if (this.company) {
      this.loadingSaving = true;
      await CompanyModule.approveCompany(this.company)
        .then((company: ICompany) => {
          if (this.company && company) {
            this.company.isApproved = company.isApproved;
            this.company.approvedAt = company.approvedAt;
            this.showSnackbar('Company approved!');
          }
        })
        .finally(() => {
          this.loadingSaving = false;
        });
    }
  }

  prepareActivies(): void {
    this.addActivity(
      'Created Company',
      EventType.create,
      this.company?.createdAt,
    );

    this.addActivity(
      `Company '${this.company?.name}' got approved`,
      EventType.approve,
      this.company?.approvedAt,
    );

    this.addActivity(
      `Company was deleted`,
      EventType.delete,
      this.company?.deletedAt,
    );

    for (let user of this.company?.users || []) {
      this.addActivity(
        `User ${user.name} (${user.email}) created`,
        EventType.create,
        user.createdAt,
      );
      this.addActivity(
        `User ${user.name} (${user.email}) updated (latest update)`,
        EventType.update,
        user.updatedAt,
      );
      this.addActivity(
        `User ${user.name} (${user.email}) confirmed email`,
        EventType.emailConfirm,
        user.emailConfirmedAt,
      );
      this.addActivity(
        `User ${user.name} (${user.email}) deleted`,
        EventType.delete,
        user.deletedAt,
      );
    }

    for (let jobOffer of this.company?.jobOffers || []) {
      this.addActivity(
        `Job offer '${jobOffer.title}' created`,
        EventType.create,
        jobOffer.createdAt,
      );

      this.addActivity(
        `Job offer '${jobOffer.title}' updated (latest update)`,
        EventType.update,
        jobOffer.updatedAt,
      );

      this.addActivity(
        `Job offer '${jobOffer.title}' approved`,
        EventType.approve,
        jobOffer.approvedAt,
      );

      this.addActivity(
        `Renew Cancelled for job offer '${jobOffer.title}'`,
        EventType.cancel,
        jobOffer.renewCancelledAt,
      );

      if (jobOffer.subscriptionEndAt) {
        if (jobOffer.subscriptionEndAt > new Date()) {
          this.addActivity(
            `Subcription ended for job offer '${jobOffer.title}'`,
            EventType.ended,
            jobOffer.subscriptionEndAt,
          );
        } else if (jobOffer.subscriptionEndAt < new Date()) {
          let action = 'be renewed';
          if (jobOffer.isRenewCancelled) {
            action = 'end';
          }
          this.addActivity(
            `Subcription will ${action} for job offer '${jobOffer.title}'`,
            EventType.ended,
            jobOffer.subscriptionEndAt,
          );
        }
      }
    }

    this.activities.sort(function (a, b) {
      return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
    });
  }
}
