import { Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Module as Mod } from 'vuex';

export interface IEntityService<IEntity, IUpdateEntity> {
  get(id: number): Promise<IEntity>;
  getAll(): Promise<IEntity[]>;
  create(entity: IEntity): Promise<IEntity>;
  update(id: number, entity: IUpdateEntity): Promise<IEntity>;
  delete(id: number): Promise<void>;
}

interface IStoreModule<IEntity, IUpdateEntity> {
  service: IEntityService<IEntity, IUpdateEntity> | null;
  getServiceInstance(): IEntityService<IEntity, IUpdateEntity>;
  convertToUpdate(entity: IEntity): IUpdateEntity;
}

export abstract class ModuleBase<
    IEntity extends { id: number; deletedAt?: Date },
    IUpdateEntity,
  >
  extends VuexModule
  implements IStoreModule<IEntity, IUpdateEntity>
{
  service: IEntityService<IEntity, IUpdateEntity>;
  that = this; // inside of actions loose the scope of this class for functions
  abstract entity: IEntity | null;
  abstract entities: IEntity[];

  constructor(module: Mod<ThisType<unknown>, unknown>) {
    super(module);
    this.service = this.getServiceInstance();
  }

  abstract getServiceInstance(): IEntityService<IEntity, IUpdateEntity>;
  abstract convertToUpdate(entity: IEntity): IUpdateEntity;

  @Mutation
  loadMutation(entity: IEntity): void {
    this.entity = entity;
  }

  @Action({ commit: 'loadMutation' })
  async load(entityId: number): Promise<IEntity> {
    const response = await this.service.get(entityId);
    return response;
  }

  @Mutation
  loadAllMutation(entities: IEntity[]): void {
    this.entities = entities;
  }

  @Action({ commit: 'loadAllMutation' })
  async loadAll(): Promise<IEntity[]> {
    const response = await this.service.getAll();
    return response;
  }

  @Mutation
  createMutation(entity: IEntity): void {
    this.entities.push(entity);
  }

  @Action({ commit: 'createMutation' })
  async create(entity: IEntity): Promise<IEntity> {
    const newEntity = await this.service.create(entity);
    return newEntity;
  }

  @Mutation
  updateMutation(entity: IEntity): void {
    const updatedIndex = this.entities.findIndex((x) => x.id == entity.id);
    if (updatedIndex > -1) {
      this.entities[updatedIndex] = entity;
    }
  }

  @Action({ commit: 'updateMutation' })
  async update(entity: IEntity): Promise<IEntity> {
    const updatedEntity = await this.service.update(
      entity.id,
      this.that.convertToUpdate(entity),
    );
    return updatedEntity;
  }

  @Mutation
  deleteMutation(entityId: number): void {
    const index = this.entities.findIndex((x) => x.id == entityId);
    if (index > -1 && this.entities[index]) {
      this.entities[index].deletedAt = new Date();
    }
  }

  @Action({ commit: 'deleteMutation' })
  async delete(entityId: number): Promise<number> {
    await this.service.delete(entityId);
    return entityId;
  }
}
