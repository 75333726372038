













































import { auditLevels, IAudit } from "@/store/modules/AuditModule";
import { Component, Vue, ModelSync } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AuditLogTableDensed extends Vue {
  @ModelSync('value', 'input', { type: Array })
  readonly auditLogs!: IAudit[];
  auditLevels = auditLevels;

  rowColor(item: IAudit): string {
    return auditLevels[item.level].rowColor;
  }
}
