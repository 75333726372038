import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
import BlogArticleService from '@/services/api/BlogArticleService';
import { IEntityService, ModuleBase } from '../ModuleBase';
import { IBlogCategory } from './BlogCategoryModule';
import { IBlogAuthor } from './BlogAuthorModule';

export interface IBlogArticleState {
  entities: IBlogArticle[];
  entity: IBlogArticle | null;
}
export interface IBlogArticle {
  id: number;
  title: string;
  imageTitle: string;
  imageAlt: string;
  slug: string;
  description: string;
  content: string;
  canonicalUrl: string;
  author?: IBlogAuthor;
  authorId?: number;
  mainCategoryId: number;
  mainCategory?: IBlogCategory;
  categories: IBlogCategory[];
  monthlyViews: number;
  isRepublished: boolean;
  isPublished: boolean;
  publishedAt?: Date;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IBlogArticleUpdate {
  title: string;
  imageTitle: string;
  imageAlt: string;
  slug: string;
  description: string;
  content: string;
  canonicalUrl: string;
  authorId?: number;
  mainCategoryId: number;
  categories: IBlogCategory[];
  monthlyViews: number;
  isRepublished: boolean;
}

export interface IBlogArticleImageUpload extends IImageUploadOptions {
  slug: string;
}

export enum ImageFitMode {
  contain = 'contain',
  cover = 'cover',
  fill = 'fill',
  inside = 'inside',
  outside = 'outside',
}

export interface IImageUploadOptions {
  title: string;
  altText: string;
  caption: string;
  isResize?: boolean | null;
  width?: number | null;
  height?: number | null;
  fitMode?: ImageFitMode | string | null;
  formats?: string[] | null;
}

export interface IPublishArticleDto {
  id: number;
  publishAt: Date | null;
}

@Module({ dynamic: true, store, name: 'blog-articles', namespaced: true })
export class BlogArticleModule
  extends ModuleBase<IBlogArticle, IBlogArticleUpdate>
  implements IBlogArticleState
{
  entity: IBlogArticle | null = null;
  entities: IBlogArticle[] = [];

  @Mutation
  async publishBlogArticleMutation(article: IBlogArticle): Promise<void> {
    this.entity = article;
  }

  @Action({ commit: 'publishBlogArticleMutation' })
  async publishBlogArticle(
    publishDto: IPublishArticleDto,
  ): Promise<IBlogArticle> {
    const article = await new BlogArticleService().publishBlogArticle(
      publishDto.id,
      publishDto.publishAt,
    );
    return article.data;
  }

  getServiceInstance(): IEntityService<IBlogArticle, IBlogArticleUpdate> {
    return new BlogArticleService();
  }

  convertToUpdate(entity: IBlogArticle): IBlogArticleUpdate {
    return {
      title: entity.title,
      description: entity.description,
      slug: entity.slug,
      content: entity.content,
      imageTitle: entity.imageTitle,
      imageAlt: entity.imageAlt,
      canonicalUrl: entity.canonicalUrl,
      authorId: entity.authorId,
      mainCategoryId: entity.mainCategoryId,
      categories: entity.categories,
      monthlyViews: entity.monthlyViews,
      isRepublished: entity.isRepublished,
    } as IBlogArticleUpdate;
  }
}

export default getModule(BlogArticleModule, store);
