import ApiService from './base/ApiService';
import {
  IJobOffer,
  IJobOfferLoadAllFilter,
  IJobOfferUpdate,
} from '../../store/modules/JobOfferModule';
import BaseApiService from './base/BaseApiService';

export default class JobOfferService extends ApiService<
  IJobOffer,
  IJobOfferUpdate
> {
  constructor() {
    super('joboffers');
  }

  async getAllFiltered(filter: IJobOfferLoadAllFilter): Promise<IJobOffer[]> {
    const response = await BaseApiService.getInstance().post<IJobOffer[]>(
      `${this.namespace}/filtered`,
      filter,
    );
    return response.data as IJobOffer[];
  }
}
