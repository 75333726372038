var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('subscription-data-table-dialogs',{scopedSlots:_vm._u([{key:"form-create",fn:function(ref){
var item = ref.item;
var rules = ref.rules;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":[rules.required, rules.email],"label":"Email"},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.allProfessions,"attach":"","chips":"","label":"Professions","multiple":""},model:{value:(item.professions),callback:function ($$v) {_vm.$set(item, "professions", $$v)},expression:"item.professions"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.allJobTypes,"attach":"","chips":"","label":"Job Types","multiple":""},model:{value:(item.jobTypes),callback:function ($$v) {_vm.$set(item, "jobTypes", $$v)},expression:"item.jobTypes"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":"Subscription is active"},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":"E-mail Confirmed"},model:{value:(item.isEmailConfirmed),callback:function ($$v) {_vm.$set(item, "isEmailConfirmed", $$v)},expression:"item.isEmailConfirmed"}})],1)],1)],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(!item.isActive)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("remove_circle")]):_vm._e(),(item.isActive)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.isEmailConfirmed",fn:function(ref){
var item = ref.item;
return [(!item.isEmailConfirmed)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" hourglass_bottom ")]):_vm._e(),(!item.isEmailConfirmed)?_c('div',{staticClass:"caption text--disabled"},[_vm._v(" Signed up "+_vm._s(_vm._f("fromNow")(item.createdAt))+" ")]):_vm._e(),(item.isEmailConfirmed)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("verified")]):_vm._e()]}},{key:"item.professions",fn:function(ref){
var item = ref.item;
return _vm._l((item.professions),function(prof){return _c('v-chip',{key:prof,staticClass:"ma-1 light-blue lighten-3",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(prof)+" ")])})}},{key:"item.jobTypes",fn:function(ref){
var item = ref.item;
return _vm._l((item.jobTypes),function(type){return _c('v-chip',{key:type,staticClass:"ma-1 light-green lighten-2",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(type)+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }