







































































































































































































































































































































































import NwMarkdownEditor, {
  IMarkdownImageData,
} from '@/components/NwMarkdownEditor.vue';
import BlogArticleService from '@/services/api/BlogArticleService';
import BlogArticleModule, {
  IBlogArticle,
  IBlogArticleImageUpload,
  IImageUploadOptions,
  ImageFitMode,
  IPublishArticleDto,
} from '@/store/modules/BlogArticleModule';
import BlogAuthorModule, {
  IBlogAuthor,
} from '@/store/modules/BlogAuthorModule';
import BlogCategoryModule, {
  IBlogCategory,
} from '@/store/modules/BlogCategoryModule';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import DatetimePicker from 'vuetify-datetime-picker';

Component.registerHooks(['beforeRouteLeave']);

Vue.use(DatetimePicker);

@Component({
  components: {
    NwMarkdownEditor,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class BlogArticleEdit extends Vue {
  isEditMode = false;
  hasChanges = false;
  loading = false;
  loadingSaving = false;
  blogArticle: IBlogArticle | null = {
    id: -1,
    title: '',
    description: '',
    isPublished: false,
    isRepublished: false,
    imageTitle: '',
    imageAlt: '',
    slug: '',
    content: '',
    canonicalUrl: '',
    author: undefined,
    mainCategoryId: 0,
    mainCategory: undefined,
    publishedAt: undefined,
    categories: [],
    monthlyViews: 0,
  };
  isEditSlug = false;
  allAuthors: IBlogAuthor[] = [];
  allCategories: IBlogCategory[] = [];
  previewExtensions = null;
  hasToolbar = true;
  scheduleMenu = false;
  breadcrumbs = [
    {
      text: 'Dashboard',
      disabled: false,
      href: '/',
    },
    {
      text: 'Blog Articles',
      disabled: false,
      href: '/blog-articles',
    },
    {
      text: 'Edit Blog Article',
      disabled: true,
    },
  ];
  tab = null;
  snackbar = {
    show: false,
    text: '',
    success: true,
  };
  rules = {
    required: (value: unknown): boolean | string => !!value || 'Required.',
  } as any;

  frontendUrl = process.env.VUE_APP_APP_URL || 'http://localhost:3000';

  menu = false;
  mainCategoryName = '-';
  selectedMainCategory: IBlogCategory | null = null;
  mainCategorySelection: number[] = [];

  uploadFormValid = false;
  imageUploadDialog = false;
  imageUploadSaveCallback = (): void => {
    return;
  };
  featuredImageDefaultValue = {};
  isImageResizeAllowed = true;
  isImageCaptionAllowed = true;
  imageFitModes = Object.values(ImageFitMode);
  uploadOptionsDefault: IImageUploadOptions = {
    title: '',
    altText: '',
    caption: '',
    isResize: null,
    width: null,
    height: null,
    fitMode: 'contain',
    formats: ['jpg', 'webp'],
  };
  uploadOptions: IImageUploadOptions = this.uploadOptionsDefault;
  featuredImageUrl = '';
  publishAt: Date | null = null;

  @Watch('blogArticle', { deep: true })
  changedBlogArticle(newArt: IBlogArticle, old: IBlogArticle): void {
    console.log('changedBlogArticle', newArt, old);
    if (this.isEditMode && old.id == -1) { //skip first assignment of blog article
      return;
    }
    this.hasChanges = true;
  }

  @Watch('selectedMainCategory')
  selectedMainCategoryChanged(newCategory: IBlogCategory): void {
    this.mainCategoryName = newCategory.name;
  }

  async mounted(): Promise<void> {
    const blogArticleId = parseInt(this.$route.params.id);
    if (blogArticleId) {
      this.isEditMode = true;
      await BlogArticleModule.load(blogArticleId);
      this.blogArticle = BlogArticleModule.entity;

      if (this.blogArticle && this.blogArticle.mainCategory) {
        const id = this.blogArticle.mainCategory.id;
        this.selectedMainCategory = this.blogArticle.mainCategory;
        this.mainCategorySelection = [id];
      }

      if (this.blogArticle && this.blogArticle.imageTitle) {
        const { slug, imageTitle } = this.blogArticle;
        this.featuredImageUrl = `${this.frontendUrl}/img/blog/posts/${slug}/${imageTitle}.png`;
        this.featuredImageDefaultValue = { name: this.blogArticle.imageTitle };
      }
    }

    await BlogCategoryModule.loadAll();
    this.allCategories = BlogCategoryModule.entities;

    await BlogAuthorModule.loadAll();
    this.allAuthors = BlogAuthorModule.entities;

    const lastBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
    if (lastBreadcrumb) {
      let defaultTitle = this.isEditMode
        ? `Edit Blog Article`
        : 'New Blog Article';

      lastBreadcrumb.text = this.blogArticle?.title || defaultTitle;
    }

    this.loading = false;
  }

  beforeRouteLeave(to: unknown, from: unknown, next: any): void {
    if (!this.hasChanges) {
      next();
      return;
    }
    const answer = window.confirm(
      'Do you really want to leave? you have unsaved changes!'
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  }

  isArticleScheduled(article: IBlogArticle): boolean {
    if (!article.publishedAt) {
      return false;
    }
    return new Date(article.publishedAt) > new Date();
  }

  async getSelectedValue(selectedIds: number[]): Promise<void> {
    if (selectedIds.length > 0) {
      const lastId: number = selectedIds[selectedIds.length - 1];
      const selectedCat = this.getCategoryById(this.allCategories, lastId);
      this.mainCategorySelection = [lastId];
      if (this.blogArticle && selectedCat) {
        this.selectedMainCategory = selectedCat; //to update the name in the text editor
        this.blogArticle.mainCategoryId = selectedCat.id;
        this.blogArticle.mainCategory = selectedCat;
        if (!this.blogArticle.categories.find((x) => x.id == selectedCat.id)) {
          this.blogArticle.categories.push(selectedCat);
        }
      }
    }
  }

  getCategoryById(list: IBlogCategory[], lastId: number): IBlogCategory | null {
    for (const cat of list) {
      if (cat.id == lastId) {
        return cat;
      } else {
        if (cat.children && cat.children.length > 0) {
          const category = this.getCategoryById(cat.children, lastId);
          if (category) {
            return category;
          }
        }
      }
    }
    return null;
  }

  async uploadImage(
    files: any,
    insertInEditor: { (imageData?: IMarkdownImageData): void },
  ): Promise<void> {
    this.imageUploadDialog = true;
    this.uploadOptions.title = this.sanitizeImageTitle(files[0].name);
    this.isImageResizeAllowed = true;
    this.isImageCaptionAllowed = true;

    this.imageUploadSaveCallback = () => {
      const valid = (this.$refs.uploadForm as any).validate();
      if (!valid) {
        return false;
      }

      this.uploadOptions.title = this.sanitizeImageTitle(
        this.uploadOptions.title,
      );
      const imageData: IBlogArticleImageUpload = {
        slug: this.blogArticle?.slug || '',
        ...this.uploadOptions,
      };
      new BlogArticleService()
        .uploadImage(files[0], imageData)
        .then((imgUrl: any) => {
          insertInEditor({
            url: imgUrl['jpg'],
            title: this.sanitizeImageTitle(this.uploadOptions.title),
            imgAlt: this.uploadOptions.altText,
            caption: this.uploadOptions.caption,
          });
          this.uploadOptions = this.uploadOptionsDefault;

          this.imageUploadDialog = false;
        });
    };
  }

  async featuredImageUpload(file: any): Promise<void> {
    if (!file || !file.name) {
      return;
    }

    this.imageUploadDialog = true;
    this.uploadOptions.title = this.sanitizeImageTitle(file.name);
    this.isImageResizeAllowed = false;
    this.isImageCaptionAllowed = false;

    this.imageUploadSaveCallback = () => {
      const valid = (this.$refs.uploadForm as any).validate();
      if (!valid) {
        return false;
      }
      this.uploadOptions.width = 1120;
      this.uploadOptions.height = 630;
      this.uploadOptions.fitMode = ImageFitMode.cover;
      this.uploadOptions.title = this.sanitizeImageTitle(
        this.uploadOptions.title,
      );
      const imageData: IBlogArticleImageUpload = {
        slug: this.blogArticle?.slug || '',
        ...this.uploadOptions,
        formats: ['png', 'webp'],
      };

      new BlogArticleService()
        .uploadImage(file, imageData)
        .then((imgUrl: any) => {
          this.uploadOptions = this.uploadOptionsDefault;
          this.featuredImageUrl = imgUrl['png'];

          if (this.blogArticle) {
            this.blogArticle.imageTitle = this.uploadOptions.title;
            this.blogArticle.imageAlt = this.uploadOptions.altText;
          }

          this.imageUploadDialog = false;
      });
    };
  }

  sanitizeImageTitle(title: string): string {
    return title
      .replace(/\.[^/.]+$/, '')
      .replace(/\W+/g, '-')
      .toLowerCase();
  }

  async applyChanges(callback: { (): void } | null = null): Promise<void> {
    this.hasChanges = false;
    this.loadingSaving = true;
    if (!this.blogArticle) {
      this.loadingSaving = false;
      console.error('Blog Article not defined!');
      return;
    }
    let action = (article: IBlogArticle) => BlogArticleModule.update(article);
    if (!this.isEditMode) {
      action = (article: IBlogArticle) => BlogArticleModule.create(article);
    }
    await action(this.blogArticle)
      .then((article: IBlogArticle) => {
        this.loadingSaving = false;
        if (callback && typeof callback == 'function') {
          callback();
        } else {
          if (!this.isEditMode) {
            this.isEditMode = true;
            this.blogArticle = article;
            this.$router.push({
              name: 'Edit Blog Article',
              params: { id: article.id.toString() },
            });
            return;
          } else {
            this.blogArticle = article;
            this.showSnackbar('Blog Article updated.');
          }
        }
      })
      .catch((error) => {
        this.loadingSaving = false;
        console.error(error);
        this.showSnackbar('Error: Blog Article could not be updated!', false);
      });
  }

  async saveExit(): Promise<void> {
    this.applyChanges(() => {
      this.$router.push({ name: 'Blog Articles' });
    });
  }

  async publishAndExit(): Promise<void> {
    if (!this.blogArticle) {
      return;
    }
    this.blogArticle.isPublished = true;
    this.applyChanges(() => {
      this.publishBlogArticle(() => {
        this.$router.push({ name: 'Blog Articles' });
      });
    });
  }

  showSnackbar(text: string, success = true): void {
    this.snackbar.show = true;
    this.snackbar.text = text;
    this.snackbar.success = success;
  }

  cancel(): void {
    this.$router.push({ name: 'Blog Articles' });
  }

  async publishBlogArticle(
    callback: { (): void } | null = null,
  ): Promise<void> {
    if (this.blogArticle) {
      this.loadingSaving = true;
      await BlogArticleModule.publishBlogArticle({
        id: this.blogArticle.id,
        publishAt: this.publishAt,
      } as IPublishArticleDto)
        .then((blogArticle: IBlogArticle) => {
          if (this.blogArticle && blogArticle) {
            this.blogArticle = blogArticle;

            if (callback && typeof callback == 'function') {
              callback();
            } else {
              this.showSnackbar('Blog Article published.');
            }
          }
        })
        .finally(() => {
          this.loadingSaving = false;
        });
    }
  }
}
