import SubscriptionService from '@/services/api/SubscriptionService';
import { getModule, Module } from 'vuex-module-decorators';
import store from '..';
import { IEntityService, ModuleBase } from '../ModuleBase';
import { JobType, Profession } from './JobOfferModule';
import { IUser } from './UserModule';

export interface ISubscription {
  id: number;
  email: string;
  isActive: boolean;
  isEmailConfirmed: boolean;
  deleteReason?: string;
  professions: Profession[];
  jobTypes: JobType[];
  userId?: number;
  user?: IUser;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface ISubscriptionUpdate {
  id: number;
  email: string;
  isActive: boolean;
  isEmailConfirmed: boolean;
  professions: Profession[];
  jobTypes: JobType[];
}

export interface ISubscriptionState {
  entity: ISubscription | null;
  entities: ISubscription[];
}

@Module({ dynamic: true, store, name: 'subscriptions', namespaced: true })
export class SubscriptionModule
  extends ModuleBase<ISubscription, ISubscriptionUpdate>
  implements ISubscriptionState
{
  entity: ISubscription | null = null;
  entities: ISubscription[] = [];

  getServiceInstance(): IEntityService<ISubscription, ISubscriptionUpdate> {
    return new SubscriptionService();
  }

  convertToUpdate(entity: ISubscription): ISubscriptionUpdate {
    return {
      id: entity.id,
      email: entity.email,
      isActive: entity.isActive,
      isEmailConfirmed: entity.isEmailConfirmed,
      professions: entity.professions,
      jobTypes: entity.jobTypes,
    } as ISubscriptionUpdate;
  }
}

export default getModule(SubscriptionModule, store);
