import AuthHelper, { Roles } from '@/helpers/AuthHelper';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '../views/Dashboard.vue';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Nomadswork Admin';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/job-offers',
    name: 'Job Offers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "job-offers" */ '../views/JobOffers.vue'),
  },
  {
    path: '/job-offers/edit/:id',
    name: 'Edit Job Offer',
    component: () =>
      import(
        /* webpackChunkName: "job-offer-edit" */ '../views/JobOfferEdit.vue'
      ),
  },
  {
    path: '/blog-articles',
    name: 'Blog Articles',
    component: () =>
      import(
        /* webpackChunkName: "blog-articles" */ '../views/BlogArticles.vue'
      ),
  },
  {
    path: '/blog-articles/edit/:id',
    name: 'Edit Blog Article',
    component: () =>
      import(
        /* webpackChunkName: "blog-article-edit" */ '../views/BlogArticleEdit.vue'
      ),
  },
  {
    path: '/blog-articles/add',
    name: 'Add Blog Article',
    component: () =>
      import(
        /* webpackChunkName: "blog-article-add" */ '../views/BlogArticleEdit.vue'
      ),
  },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
  },
  {
    path: '/companies/edit/:id',
    name: 'Edit Company',
    component: () =>
      import(/* webpackChunkName: "company-edit" */ '../views/CompanyEdit.vue'),
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../views/Subscriptions.vue'
      ),
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () =>
      import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
  },
  {
    path: '/audits',
    name: 'Audits',
    component: () =>
      import(/* webpackChunkName: "audits" */ '../views/Audits.vue'),
  },
  {
    path: '/cron-jobs',
    name: 'Cron Jobs',
    component: () =>
      import(/* webpackChunkName: "cron-jobs" */ '../views/AuditsCrons.vue'),
  },
  {
    path: '/page-not-found',
    name: 'Page Not Found',
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'
      ),
  },
  {
    path: '*',
    redirect: '/page-not-found',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta && to.meta.title) {
      document.title = `${to.meta.title} - ${DEFAULT_TITLE}`;
    } else if (to.name) {
      document.title = `${to.name} - ${DEFAULT_TITLE}`;
    } else {
      document.title = DEFAULT_TITLE;
    }
  });
});

// very basic "setup" of a global guard
router.beforeEach(async (to, from, next) => {
  if (to.name == undefined) {
    router.push({ path: '/page-not-found' });
    return;
  }

  //base page in the login page.
  const public_pages = ['Login', 'Page Not Found'];

  // check if "to"-route is "callback" and allow access
  if (public_pages.includes(to.name)) {
    next();
  } else if (
    AuthHelper.isAuthenticated() ||
    (await AuthHelper.tryRefreshToken())
  ) {
    // if authenticated allow access
    if (
      to.meta &&
      validatePageAuthentication(to.meta.roles, to.meta.permissions)
    ) {
      next();
    } else {
      router.push({ path: '/page-not-found' });
    }
  } else {
    let referrer = '';
    if (to.name && to.fullPath != '/') {
      referrer = `?refer=${to.fullPath}`;
    }

    router.push({ path: `/login${referrer}` });
  }
});

function validatePageAuthentication(
  roles: Roles[],
  permissions: Permissions[],
) {
  if (!roles && !permissions) {
    return true;
  }

  if (!AuthHelper.hasAnyRole(roles)) {
    return false;
  } else {
    if (!permissions) {
      return true;
    }
    return AuthHelper.hasAnyPermission(permissions);
  }
}

export default router;
