




















































import AuthHelper from '@/helpers/AuthHelper';
import { Component, Vue } from 'vue-property-decorator';
import AuthModule, { IUserToken } from '@/store/modules/AuthModule';

@Component({
  components: {},
})
export default class Login extends Vue {
  rules = {
    required: (value: unknown): string | boolean =>
      !!value || 'This value is required',
  };
  hidePassword = true;
  error = false;
  loading = false;
  showResult = false;
  result = 'result';

  userEmail = '';
  password = '';

  async login(): Promise<void> {
    this.loading = true;

    if (!this.userEmail || !this.password) {
      this.result = "Email and Password can't be null.";
      this.showResult = true;
      this.loading = false;

      return;
    }

    const token: IUserToken | null = await AuthModule.login({
      username: this.userEmail,
      password: this.password,
    });

    if (!token || !AuthHelper.isAuthenticated(token)) {
      this.error = true;
      this.result = 'Email or Password is incorrect.';
      this.showResult = true;
      this.loading = false;
      return;
    }

    Vue.prototype.$currentUser = {
      id: token.sub,
    };

    const referrerQuery = this.$route.query['refer'];
    const referrer = referrerQuery && referrerQuery.toString();
    if (referrer) {
      this.$router.push(referrer);
      return;
    }

    this.$router.push({ name: 'Dashboard' });
  }
}
