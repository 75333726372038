var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('blog-article-data-table-dialogs',{scopedSlots:_vm._u([{key:"delete-dialog-text",fn:function(ref){
var item = ref.item;
return [_vm._v(" Title: "+_vm._s(item.title)+" ")]}},{key:"item.mainCategory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mainCategory.name)+" ")]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.name)+" ")]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fromNow")(item.publishedAt))+" ")]}},{key:"item.isPublished",fn:function(ref){
var item = ref.item;
return [(!item.isPublished)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("remove_circle")]):_vm._e(),(item.isPublished && !_vm.isArticleScheduled(item))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" check_circle ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.isPublished && _vm.isArticleScheduled(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" schedule ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.publishedAt,'DD. MMM YYYY HH:mm')))])])]}},{key:"item.imageTitle",fn:function(ref){
var item = ref.item;
return [(item.imageTitle)?_c('div',{staticStyle:{"position":"relative"}},[(item.imageTitle)?_c('v-img',{staticClass:"rounded ma-2",attrs:{"max-width":"100","src":_vm.getImageUrl(item.imageTitle, item.slug)}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',{staticStyle:{"position":"absolute","left":"75px","bottom":"-15px"}},[_c('v-img',_vm._g(_vm._b({attrs:{"src":_vm.frontendUrl + item.author.imagePath,"max-width":"30","max-height":"30"}},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.author ? item.author.name : ''))])])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }