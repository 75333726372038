import ApiService from './base/ApiService';
import {
  IOrderItem,
  IOrderItemUpdate,
} from '../../store/modules/OrderItemModule';
import BaseApiService from './base/BaseApiService';

export default class OrderItemService extends ApiService<
  IOrderItem,
  IOrderItemUpdate
> {
  constructor() {
    super('order-items');
  }

  async getByOrderId(orderId: number): Promise<IOrderItem[]> {
    const response = await BaseApiService.getInstance().get<IOrderItem[]>(
      `${this.namespace}/byorder/${orderId}`,
    );
    return response.data as IOrderItem[];
  }
}
