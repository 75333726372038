import CompanyService from '@/services/api/CompanyService';
import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';
import store from '..';
import { IEntityService, ModuleBase } from '../ModuleBase';
import { IJobOffer } from './JobOfferModule';
import { IUser } from './UserModule';

export interface ICompany {
  id: number;
  name: string;
  email: string;
  logoBase64: string;
  slug: string;
  companyhq: string;
  philosophy: string;
  description: string;
  url: string;
  isApproved: boolean;
  jobOffers: IJobOffer[];
  users: IUser[];
  legalnotice: string;
  privacypolicy: string;
  approvalCode?: string;
  approvedAt?: Date;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ICompanyUpdate extends Partial<ICompany> {
  id: number;
  name: string;
  email: string;
  companyhq: string;
  philosophy: string;
  description: string;
  url: string;
  slug: string;
  legalnotice: string;
  privacypolicy: string;
}

export interface ICompanyState {
  entity: ICompany | null;
  entities: ICompany[];
}

@Module({ dynamic: true, store, name: 'companies', namespaced: true, })
export class CompanyModule
  extends ModuleBase<ICompany, ICompanyUpdate>
  implements ICompanyState
{
  entity: ICompany | null = null;
  entities: ICompany[] = [];

  getServiceInstance(): IEntityService<ICompany, ICompanyUpdate> {
    return new CompanyService();
  }

  convertToUpdate(entity: ICompany): ICompanyUpdate {
    return {
      id: entity.id,
      slug: entity.slug,
      name: entity.name,
      email: entity.email,
      companyhq: entity.companyhq,
      philosophy: entity.philosophy,
      description: entity.description,
      url: entity.url,
      legalnotice: entity.legalnotice,
      privacypolicy: entity.privacypolicy,
    } as ICompanyUpdate;
  }

  @Mutation
  async approveCompanyMutation(entity: ICompany): Promise<void> {
    this.entity = entity;
    if (this.entity) {
      this.entity.isApproved = true;
      this.entity.approvedAt = new Date();
    }
  }

  @Action({ commit: 'approveCompanyMutation' })
  async approveCompany(company: ICompany): Promise<ICompany> {
    const url = `${
      process.env.VUE_APP_APP_URL || 'http://localhost:3000'
    }/company/approve/${company.id}/${company.approvalCode}`;
    window.open(url, '_blank');
    //await new CompanyService().approveCompany(company);
    return company;
  }
}

export default getModule(CompanyModule, store);
