import AuthService from '@/services/api/AuthService';
import UserModule from './UserModule';
import LocalStorageHelper, { StorageKeys } from '@/helpers/LocalStorageHelper';
import AuthHelper from '@/helpers/AuthHelper';

export interface IUserToken {
  sub: number;
  email: string;
  exp: number;
}

export interface IAuthResponse {
  access_token: string;
  refresh_token: string;
}

export interface ILoginUserParams {
  username: string;
  password: string;
}

export class AuthModule {
  async login(params: ILoginUserParams): Promise<IUserToken | null> {
    const result = await AuthService.login(
      params.username,
      params.password,
    ).catch((error) => {
      return error;
    });

    if (!result || !result.data || result.status != 200) {
      return null;
    }

    this.saveTokensLocally(result.data);

    const userToken = AuthHelper.decodeAuthToken(result.data.access_token);
    if (!userToken) {
      return null;
    }
    await UserModule.load(userToken.sub);

    return userToken;
  }

  async refreshToken(refreshToken: string): Promise<void> {
    const result = await AuthService.refreshToken(refreshToken).catch(
      (error) => {
        return error;
      },
    );

    if (!result || !result.data || result.status != 200) {
      return;
    }

    this.saveTokensLocally(result.data);
  }

  saveTokensLocally(authResponse: IAuthResponse): void {
    const { access_token, refresh_token } = authResponse;

    LocalStorageHelper.setItem(StorageKeys.token, access_token); //TODO: Save in HttpOnly-Cache! For Security
    LocalStorageHelper.setItem(StorageKeys.refresh_token, refresh_token); // this can be in local storage
  }
}

const authModule = new AuthModule();
export default authModule;
