import {
  IAudit,
  IAuditLoadAllFilter,
  IAuditUpdate,
} from '@/store/modules/AuditModule';
import ApiService from './base/ApiService';
import BaseApiService from './base/BaseApiService';
import qs from 'qs-stringify';

export default class AuditService extends ApiService<IAudit, IAuditUpdate> {
  constructor() {
    super('audits');
  }

  async getAllFiltered(filter: IAuditLoadAllFilter): Promise<IAudit[]> {
    const response = await BaseApiService.getInstance().post<IAudit[]>(
      `${this.namespace}/filtered`,
      filter,
    );
    return response.data as IAudit[];
  }

  async getFilteredByTags(tags: string[]): Promise<IAudit[]> {
    const response = await BaseApiService.getInstance().get<IAudit[]>(
      `${this.namespace}/filtered/tags`,
      {
        params: { tags },
        paramsSerializer: (params) => {
          return qs(params);
        },
      },
    );
    return response.data as IAudit[];
  }
}
