import { IEntityService } from '@/store/ModuleBase';
import BaseApiService from './BaseApiService';

export default class ApiService<
  IEntity extends { id: number },
  IEntityUpdate extends Partial<IEntity>,
> implements IEntityService<IEntity, IEntityUpdate>
{
  namespace = '';

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  async get(id: number): Promise<IEntity> {
    const response = await BaseApiService.getInstance().get<IEntity>(
      `${this.namespace}/${id}`,
    );
    return response.data as IEntity;
  }

  async getAll(): Promise<IEntity[]> {
    const response = await BaseApiService.getInstance().get<IEntity[]>(
      `${this.namespace}/all`,
    );
    return response.data as IEntity[];
  }

  async create(entity: IEntity): Promise<IEntity> {
    const response = await BaseApiService.getInstance().post<IEntity>(
      `${this.namespace}`,
      entity,
    );
    return response.data as IEntity;
  }

  async update(id: number, entity: IEntityUpdate): Promise<IEntity> {
    const response = await BaseApiService.getInstance().post<IEntity>(
      `${this.namespace}/${id}`,
      entity,
    );
    return response.data as IEntity;
  }

  async delete(id: number): Promise<void> {
    await BaseApiService.getInstance().delete(`${this.namespace}/${id}`);
  }
}
