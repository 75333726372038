








































































import DataTableDialogs from '@/components/DataTable-Dialogs.vue';
import { ICompany } from '@/store/modules/CompanyModule';
import JobOfferModule, {
  IJobOffer,
  IJobOfferLoadAllFilter,
  IJobOfferUpdate,
} from '@/store/modules/JobOfferModule';
import moment from 'moment';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
class JobOfferDataTableDialogs extends Mixins<
  DataTableDialogs<IJobOffer, IJobOfferUpdate>
>(DataTableDialogs) {
  companyFilter = '';

  setup(): void {
    this.entityName = 'Job Offer';
    this.entityNamePl = 'Job Offers';
    this.module = JobOfferModule;
    this.loadFromParent = true;
    this.showCreateButton = false;

    this.addHeaders([
      { text: 'title', value: 'title' },
      { text: 'Link', value: 'link' },
      { text: 'Online', value: 'online' },
      { text: 'Approved', value: 'isApproved' },
      { text: 'Active', value: 'isSuspended' },
      { text: 'Renew Active', value: 'isRenewCancelled' },
      {
        text: 'Company',
        value: 'company',
        filterable: true,
        filter: (value: IJobOffer) => {
          if (!value || !this.companyFilter) {
            return true;
          }
          return value.company?.name.includes(this.companyFilter) || false;
        },
      },
    ]);
  }

  //override
  editItem(item: IJobOffer): void {
    this.$router.push({
      name: 'Edit Job Offer',
      params: { id: item.id.toString() },
    });
  }

  //override
  itemRowBackground(item: IJobOffer): string {
    if (item.deletedAt != null) {
      return 'red lighten-5';
    }
    if (item.externalId != null) {
      return 'blue lighten-5';
    }
    if (!item.isApproved) {
      return 'amber lighten-5';
    }
    return '';
  }
}

@Component({
  components: {
    JobOfferDataTableDialogs,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class JobOffers extends Vue {
  withDeleted = false;
  withExternal = false;
  entities: IJobOffer[] = [];

  appUrl = process.env.VUE_APP_APP_URL || 'http://localhost:3000';

  filterOptions: IJobOfferLoadAllFilter = {
    withDeleted: false,
    withExternal: false,
    onlyExternal: false,
  };

  async mounted(): Promise<void> {
    await JobOfferModule.loadAllFiltered(this.filterOptions);
    this.entities = JobOfferModule.entities || [];
  }

  @Watch('withDeleted')
  async onWithDeletedChanged(val: boolean): Promise<void> {
    this.filterOptions.withDeleted = val;
    await JobOfferModule.loadAllFiltered(this.filterOptions);
    this.entities = JobOfferModule.entities || [];
  }

  @Watch('withExternal')
  async onWithExternalChanged(val: boolean): Promise<void> {
    this.filterOptions.withExternal = val;
    await JobOfferModule.loadAllFiltered(this.filterOptions);
    this.entities = JobOfferModule.entities || [];
  }

  editCompany(item: ICompany): void {
    this.$router.push({
      name: 'Edit Company',
      params: { id: item.id.toString() },
    });
  }
}
