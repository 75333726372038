import { Module, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { IEntityService, ModuleBase } from '../ModuleBase';
import BlogAuthorService from '@/services/api/BlogAuthorService';

export interface IBlogAuthorState {
  entities: IBlogAuthor[];
  entity: IBlogAuthor | null;
}
export interface IBlogAuthor {
  id: number;
  name: string;
  imagePath: string;
  bio: string;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IBlogAuthorUpdate {
  name: string;
  imagePath: string;
  bio: string;
}

@Module({ dynamic: true, store, name: 'blog-authors', namespaced: true })
export class BlogAuthorModule
  extends ModuleBase<IBlogAuthor, IBlogAuthorUpdate>
  implements IBlogAuthorState
{
  entity: IBlogAuthor | null = null;
  entities: IBlogAuthor[] = [];

  getServiceInstance(): IEntityService<IBlogAuthor, IBlogAuthorUpdate> {
    return new BlogAuthorService();
  }

  convertToUpdate(entity: IBlogAuthor): IBlogAuthorUpdate {
    return {
      name: entity.name,
      bio: entity.bio,
    } as IBlogAuthorUpdate;
  }
}

export default getModule(BlogAuthorModule, store);
