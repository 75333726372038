import axios, { AxiosInstance } from 'axios';
import LocalStorageHelper, { StorageKeys } from '@/helpers/LocalStorageHelper';
import AuthHelper from '@/helpers/AuthHelper';
import { ApiServiceOptions } from './ApiServiceOptions';

export default class BaseApiService {
  static getInstance(
    options: ApiServiceOptions = new ApiServiceOptions(),
  ): AxiosInstance {
    const service = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}${options.baseUrl}`,
      timeout: 90000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!options.useAuth) {
      return service;
    }

    service.interceptors.request.use(
      async (config) => {
        if (!config || !config.headers) {
          console.error('config or headers is undefined');
          return config;
        }

        let token = LocalStorageHelper.getItem(StorageKeys.token);
        if (token) {
          if (!AuthHelper.isAuthenticated()) {
            //token must be expired -> try to refresh
            if (await AuthHelper.tryRefreshToken()) {
              token = LocalStorageHelper.getItem(StorageKeys.token);
            } else {
              throw new Error('Unauthorized for this action');
            }
          }
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    return service;
  }
}
