var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('audits-data-table-dialogs',{attrs:{"filter-options":_vm.filterOptions},scopedSlots:_vm._u([{key:"toolbar-filter",fn:function(ref){return [_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6 mr-4",attrs:{"label":"Date range","prepend-icon":"date_range","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePickerMenu),callback:function ($$v) {_vm.datePickerMenu=$$v},expression:"datePickerMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-select',{staticClass:"mt-6",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.auditLevelsSelect,"menu-props":{ maxHeight: '400' },"label":"Levels","multiple":"","item-text":"name","item-value":"level"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectedLevels.length == _vm.auditLevelsSelect.length)?_c('span',[(index === 0)?_c('v-chip',[_c('span',[_vm._v("All")])]):_vm._e()],1):_c('span',[(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filterOptions.levels.length - 2)+" others) ")]):_vm._e()],1)]}}],null,true),model:{value:(_vm.selectedLevels),callback:function ($$v) {_vm.selectedLevels=$$v},expression:"selectedLevels"}}),_c('v-spacer')]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.levelInfo[item.level].icon)+" ")])]}},{key:"item.task",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.task)+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message)+" ")]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [(_vm.levelInfo[item.level])?_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.levelInfo[item.level].color,"text-color":_vm.levelInfo[item.level].textColor}},[_vm._v(" "+_vm._s(_vm.levelInfo[item.level].name)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD. MMM YYYY HH:mm'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toUtcTime")(item.createdAt))+" UTC")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.jsonData)?_c('td',{staticClass:"pt-2 pb-2",attrs:{"colspan":headers.length}},[_c('code',[_vm._v(_vm._s(item.jsonData))])]):_c('td',{staticClass:"pt-2 pb-2",attrs:{"colspan":headers.length}},[_vm._v(" No data available ")])]}}]),model:{value:(_vm.entities),callback:function ($$v) {_vm.entities=$$v},expression:"entities"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }