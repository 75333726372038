import ApiService from './base/ApiService';
import {
  IBlogCategory,
  IBlogCategoryUpdate,
} from '@/store/modules/BlogCategoryModule';

export default class BlogCategoryService extends ApiService<
  IBlogCategory,
  IBlogCategoryUpdate
> {
  constructor() {
    super('blog-categories');
  }
}
