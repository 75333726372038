import BaseApiService from '@/services/api/base/BaseApiService';
import { AxiosPromise } from 'axios';
import { ICompany, ICompanyUpdate } from '../../store/modules/CompanyModule';
import ApiService from './base/ApiService';

export default class CompanyService extends ApiService<
  ICompany,
  ICompanyUpdate
> {
  constructor() {
    super('company');
  }

  async approveCompany(company: ICompany): Promise<AxiosPromise<ICompany>> {
    return BaseApiService.getInstance().post(
      `${this.namespace}/approve/${company.id}`,
    );
  }
}
