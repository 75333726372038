import JobOfferService from '@/services/api/JobOfferService';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '..';
import { IEntityService, ModuleBase } from '../ModuleBase';
import { ICompany } from './CompanyModule';

export enum Profession {
  Programming = 'Programming (Full-Stack)',
  Prog_Backend = 'Programming (Back-End)',
  Prog_Frontend = 'Programming (Front-End)',
  DevOps_Admin = 'DevOps & Admin',
  Design = 'Design',
  Copywriting = 'Copywriting',
  Sales_Marketing = 'Sales & Marketing',
  Customer_Support = 'Customer Support',
  Other = 'Other',
}

export enum JobType {
  Fulltime = 'Full-time',
  Parttime = 'Part-time',
  Contract = 'Contract',
}

export enum RegionRestriction {
  Anywhere = 'Anywhere',
  USA = 'USA',
  Europe = 'Europe',
  Asia = 'Asia',
  Africa = 'Africa',
  Other = 'Other',
}

export enum ExternalProvider {
  remoteok = 'Remote Ok',
}

export interface IJobOffer {
  id: number;
  title: string;
  slug: string;
  profession: Profession;
  type: JobType;
  regionRestriction: RegionRestriction;
  otherRestriction?: string;
  applicationLink: string;
  description: string;
  isApproved: boolean;
  isSuspended: boolean;
  approvedAt?: Date;
  isRenewCancelled: boolean;
  renewCancelledAt?: Date;
  subscriptionEndAt?: Date;
  isHighlighted: boolean;
  companyId?: number;
  company?: ICompany;
  userId?: number;
  externalId?: string;
  externalProvider?: ExternalProvider;
  externalCompanyName?: string;
  externalCompanyLogo?: string;
  externalUrl?: string;
  approvalCode?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;

  isOnline: boolean;
  notOnlineReasons: string[];
}

export interface IJobOfferUpdate {
  title: string;
  slug: string;
  profession: Profession;
  type: JobType;
  regionRestriction: RegionRestriction;
  otherRestriction?: string;
  applicationLink: string;
  description: string;
  isSuspended: boolean;
  isRenewCancelled: boolean;
  renewCancelledAt?: Date;
  subscriptionEndAt?: Date;
  isHighlighted: boolean;
}

export interface IJobOfferState {
  entity: IJobOffer | null;
  entities: IJobOffer[];
}

export interface IJobOfferLoadAllFilter {
  withDeleted: boolean;
  withExternal: boolean;
  onlyExternal: boolean;
}

@Module({ dynamic: true, store, name: 'joboffers', namespaced: true })
export class JobOfferModule
  extends ModuleBase<IJobOffer, IJobOfferUpdate>
  implements IJobOfferState
{
  entity: IJobOffer | null = null;
  entities: IJobOffer[] = [];

  @Mutation
  loadAllFilteredMutation(entities: IJobOffer[]): void {
    this.entities = entities;
  }

  @Action({ commit: 'loadAllFilteredMutation' })
  async loadAllFiltered(filter: IJobOfferLoadAllFilter): Promise<IJobOffer[]> {
    const response = await (this.service as JobOfferService).getAllFiltered(
      filter,
    );
    return response;
  }

  @Mutation
  async approveJobOfferMutation(entity: IJobOffer): Promise<void> {
    this.entity = entity;
    if (this.entity) {
      this.entity.isApproved = true;
      this.entity.approvedAt = new Date();
    }
  }

  @Action({ commit: 'approveJobOfferMutation' })
  async approveJobOffer(jobOffer: IJobOffer): Promise<IJobOffer> {
    //await new JobOfferService().approveJobOffer(jobOffer);
    const url = `${
      process.env.VUE_APP_APP_URL || 'http://localhost:3000'
    }/remote-jobs/job/approve/${jobOffer.id}/${jobOffer.approvalCode}`;
    window.open(url, '_blank');
    return jobOffer;
  }

  getServiceInstance(): IEntityService<IJobOffer, IJobOfferUpdate> {
    return new JobOfferService();
  }

  convertToUpdate(entity: IJobOffer): IJobOfferUpdate {
    return {
      title: entity.title,
      slug: entity.slug,
      profession: entity.profession,
      type: entity.type,
      regionRestriction: entity.regionRestriction,
      otherRestriction: entity.otherRestriction,
      applicationLink: entity.applicationLink,
      description: entity.description,
      isSuspended: entity.isSuspended,
      isRenewCancelled: entity.isRenewCancelled,
      renewCancelledAt: entity.renewCancelledAt,
      subscriptionEndAt: entity.subscriptionEndAt,
      isHighlighted: entity.isHighlighted,
    } as IJobOfferUpdate;
  }
}

export default getModule(JobOfferModule, store);
