




















































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import UserModule, { IUser, IUserUpdate } from '@/store/modules/UserModule';
import { ICompany } from '@/store/modules/CompanyModule';
import DataTableDialogs from '@/components/DataTable-Dialogs.vue';
import moment from 'moment';

@Component({
  components: {},
})
class UsersDataTableDialogs extends Mixins<
  DataTableDialogs<IUser, IUserUpdate>
>(DataTableDialogs) {
  setup(): void {
    this.entityName = 'User';
    this.entityNamePl = 'Users';
    this.module = UserModule;

    this.addHeaders([
      { text: 'ID', value: 'id' },
      {
        text: 'E-Mail',
        align: 'start',
        filterable: true,
        value: 'email',
      },
      { text: 'Name', value: 'name' },
      { text: 'Active', value: 'isActive' },
      { text: 'E-mail Confirmed', value: 'isEmailConfirmed' },
      { text: 'Company', value: 'company' },
    ]);
  }
}

@Component({
  components: {
    UsersDataTableDialogs,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class Users extends Vue {
  localRules = {
    passwordCompare: (value: string, value2: string): boolean | string => {
      return value === value2 || 'The passwords must match.';
    },
  };

  editCompany(item: ICompany): void {
    this.$router.push({
      name: 'Edit Company',
      params: { id: item.id.toString() },
    });
  }
}
