



































































import { Component, Vue } from 'vue-property-decorator';
import AuditModule, {
  cronInfo,
  IAudit,
  ICronGroup,
  ICronInfo,
  auditLevels,
} from '@/store/modules/AuditModule';
import moment from 'moment';
import AuditLogTableDensed from '@/components/AuditLogTableDensed.vue';

@Component({
  components: {
    AuditLogTableDensed,
  },
  filters: {
    fromNow(date?: Date) {
      return date && moment(date).fromNow();
    },
  },
})
export default class AuditsCrons extends Vue {
  loading = true;
  crons: IAudit[] = [];
  cronsGrouped: ICronGroup[] = [];
  auditLevels = auditLevels;

  async mounted(): Promise<void> {
    this.crons = await AuditModule.loadFilteredByTags(['cron']);
    this.createCronGroups(this.crons);
    this.loading = false;
  }

  createCronGroups(crons: IAudit[]): void {
    const allTaskNames = crons.map((x) => x.task);
    const taskNames = [...new Set(allTaskNames)];

    for (const taskName of taskNames) {
      const info =
        cronInfo.find((x) => x.task == taskName) ?? ({} as ICronInfo);
      const logs = crons.filter((x) => x.task == taskName);
      const errors = logs.filter((x) => x.level < 4);

      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);
      const errorsPast7days = errors.filter(
        (x) => new Date(x.createdAt) > weekAgo,
      );

      this.cronsGrouped.push({
        task: taskName,
        info,
        errorsPast7days,
        errorCount: errors.length,
        latest: logs && logs.length > 0 ? logs[0] : ({} as IAudit),
        all: logs,
      });
    }
  }
}
