import Vue from 'vue';
import Vuex from 'vuex';
import { IBlogArticleState } from './modules/BlogArticleModule';
import { IBlogCategoryState } from './modules/BlogCategoryModule';
import { ICompanyState } from './modules/CompanyModule';
import { IJobOfferState } from './modules/JobOfferModule';
import { IStatisticState } from './modules/StatisticModule';
import { ISubscriptionState } from './modules/SubscriptionModule';
import { IUserState } from './modules/UserModule';

Vue.use(Vuex);
export interface IRootState {
  user: IUserState;
  company: ICompanyState;
  subscription: ISubscriptionState;
  jobOffer: IJobOfferState;
  blogArticle: IBlogArticleState;
  blogCategory: IBlogCategoryState;
  statistic: IStatisticState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({});
