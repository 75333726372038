import Plugin from 'markdown-it-regexp';

const handlebarsPreview = Plugin(
  /\{\{hbs ([^\} ]*)\}\}/,
  function (match: any) {
    return `<div style="background-color: #EFEFEF; color: gray; padding: 20px; border-radius: 10px">
      ${match[1]}
    </div>`;
  },
);

export default handlebarsPreview;