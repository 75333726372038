import { Module, getModule } from 'vuex-module-decorators';
import store from '..';
import { ICompany } from './CompanyModule';
import { IEntityService, ModuleBase } from '../ModuleBase';
import UserService from '@/services/api/UserService';

export interface IUser {
  id: number;
  name: string;
  password?: string;
  email: string;
  isActive: boolean;
  isEmailConfirmed: boolean;
  companyId: number;
  company?: ICompany;
  emailConfirmedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface IUserUpdate {
  name: string;
  password?: string;
  email: string;
  isActive: boolean;
  isEmailConfirmed: boolean;
}

export interface IUserState {
  entities: IUser[];
  entity: IUser | null;
}

@Module({ dynamic: true, store, name: 'users', namespaced: true })
export class UserModule
  extends ModuleBase<IUser, IUserUpdate>
  implements IUserState
{
  entity: IUser | null = null;
  entities: IUser[] = [];

  getServiceInstance(): IEntityService<IUser, IUserUpdate> {
    return new UserService();
  }

  convertToUpdate(entity: IUser): IUserUpdate {
    return {
      name: entity.name,
      password: entity.password,
      email: entity.email,
      isActive: entity.isActive,
      isEmailConfirmed: entity.isEmailConfirmed,
    } as IUserUpdate;
  }
}

export default getModule(UserModule, store);
