import axios, { AxiosInstance } from 'axios';

export interface IUnsplashApiListResult<IUnsplashEntity> {
  total: number;
  total_pages: number;
  results: IUnsplashEntity[];
}

export interface IUnsplashLink {
  self?: string;
  html?: string;
  photos?: string;
  likes?: string;
  portfolio?: string;
}

export interface IUnsplashUrl {
  raw?: string;
  full?: string;
  regular?: string;
  small?: string;
  thumb?: string;
}

export interface IUnsplashUser {
  id?: string;
  updated_at?: string;
  username?: string;
  name?: string;
  first_name: string;
  last_name: string;
  portfolio_url?: string;
  profile_image?: {
    small: string;
    medium: string;
    large: string;
  };
  bio?: string;
  location?: string;
  total_likes?: number;
  total_photos?: number;
  total_collections?: number;
  instagram_username?: string;
  twitter_username?: string;
  links?: {
    self: string;
    html: string;
    photos: string;
    likes: string;
  };
}

export interface IUnsplashImage {
  id?: string;
  created_at?: string;
  updated_at?: string;
  width?: number;
  height?: number;
  color?: string;
  blur_hash?: string;
  download?: number;
  likes?: number;
  liked_by_user?: boolean;
  alt?: string;
  description?: string;
  alt_description?: string;
  exif?: IUnsplashImageExif;
  location?: IUnsplashImageLocation;
  current_user_collections: any[];
  urls?: IUnsplashImageUrls;
  links?: IUnsplashImageLinks;
  tags?: IUnspashImageTag[];
  user?: IUnsplashUser;
}

export interface IUnspashImageTag {
  title: string;
}

export interface IUnsplashImageExif {
  make: string;
  model: string;
  name: string;
  exposure_time: string;
  aperture: string;
  focal_length: string;
  iso: number;
}

export interface IUnsplashImageLocation {
  city: string;
  country: string;
  position: {
    latitude: number;
    longitude: number;
  };
}

export interface IUnsplashImageUrls {
  raw: string;
  full: string;
  regular: string;
  small: string;
  thumb: string;
}

export interface IUnsplashImageLinks {
  self: string;
  html: string;
  download: string;
}

export class UnsplashSearchOptions {
  page = 1;
  per_page = 10;
  order_by = UnsplashOrderBy.Latest;
  orientation: UnsplashImageOrientation | null = null;
}
// export interface UnsplashCache {
//   collections: { [key: string]: IUnsplashImage[] };
//   images: IUnsplashImage[];
// }

export enum UnsplashOrderBy {
  Latest = 'latest',
  Oldest = 'oldest',
  Popular = 'popular',
}

export enum UnsplashImageOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Squarish = 'squarish',
}

export default class UnsplashApiService {
  getService(): AxiosInstance {
    const accessKey = `${process.env.VUE_APP_UNSPLASH_ACCESS_KEY}`;
    const service = axios.create({
      baseURL: `${process.env.VUE_APP_UNSPLASH_API_URL}`,
      timeout: 90000,
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        client_id: accessKey,
      },
    });

    return service;
  }

  async getPhotos(
    page = 1,
    per_page = 10,
    order_by = UnsplashOrderBy.Latest,
  ): Promise<IUnsplashImage[]> {
    const result = await this.getService().get(`/photos`, {
      params: {
        page,
        per_page,
        order_by,
      },
    });
    return result.data;
  }

  async searchPhotos(
    query: string,
    options: UnsplashSearchOptions,
  ): Promise<IUnsplashApiListResult<IUnsplashImage>> {
    const result = await this.getService().get(`/search/photos`, {
      params: {
        query,
        ...options,
      },
    });
    return result.data;
  }

  async getPhoto(id: string): Promise<IUnsplashImage> {
    return this.getService().get(`/photos/${id}`);
  }
}
