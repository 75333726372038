import BaseApiService from '@/services/api/base/BaseApiService';
import { IJobOfferStatistic } from '@/store/modules/StatisticModule';
import { AxiosPromise } from 'axios';

const namespace = 'stats';

export default class StatisticService {
  static getJobOfferStatistic(): AxiosPromise<IJobOfferStatistic> {
    return BaseApiService.getInstance().get<IJobOfferStatistic>(
      `${namespace}/joboffers`,
    );
  }
}
