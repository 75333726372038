import OrderItemService from '@/services/api/OrderItemService';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '..';
import { IEntityService, ModuleBase } from '../ModuleBase';

export interface IOrderItem {
  id: number;
  orderId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  relatedId?: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface IOrderItemUpdate {
  id: number;
}

export interface IOrderItemState {
  entity: IOrderItem | null;
  entities: IOrderItem[];
}

@Module({ dynamic: true, store, name: 'order-items', namespaced: true })
export class OrderItemModule
  extends ModuleBase<IOrderItem, IOrderItemUpdate>
  implements IOrderItemState
{
  entity: IOrderItem | null = null;
  entities: IOrderItem[] = [];
  entitiesByOrder: { [index: number]: IOrderItem[] } = {};

  getServiceInstance(): IEntityService<IOrderItem, IOrderItemUpdate> {
    return new OrderItemService();
  }

  convertToUpdate(entity: IOrderItem): IOrderItemUpdate {
    return {
      id: entity.id,
    } as IOrderItemUpdate;
  }

  @Mutation
  loadByOrderIdMutation(entities: IOrderItem[]): void {
    if (entities.length == 0) {
      return;
    }
    this.entitiesByOrder[entities[0].orderId] = entities;
  }

  @Action({ commit: 'loadByOrderIdMutation' })
  async loadByOrderId(orderId: number): Promise<IOrderItem[]> {
    const response = await (this.service as OrderItemService).getByOrderId(
      orderId,
    );
    return response;
  }
}

export default getModule(OrderItemModule, store);
