var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('job-offer-data-table-dialogs',{attrs:{"filter-options":_vm.filterOptions},scopedSlots:_vm._u([{key:"toolbar-filter",fn:function(ref){return [_c('v-chip-group',{attrs:{"column":"","multiple":""}},[_c('v-chip',{attrs:{"filter":"","outlined":""},on:{"click":function($event){_vm.withDeleted = !_vm.withDeleted}}},[_vm._v(" With Deleted ")]),_c('v-chip',{attrs:{"filter":"","outlined":""},on:{"click":function($event){_vm.withExternal = !_vm.withExternal}}},[_vm._v(" With External ")])],1)]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('a',{on:{"click":function($event){return _vm.editCompany(item.company)}}},[_vm._v(" "+_vm._s(item.company.name)+" ")]):(item.externalId)?_c('span',[_vm._v(" "+_vm._s(item.externalCompanyName)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.online",fn:function(ref){
var item = ref.item;
return [(item.isOnline)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"bordered":"","color":"grey","icon":"question_mark","overlap":""}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("remove_circle")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" Reasons why job offer is not online: "),_c('ul',_vm._l((item.notOnlineReasons),function(reason,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(reason)+" ")])}),0)])])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.isOnline)?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("launch")]),_c('a',{attrs:{"href":(_vm.appUrl + "/remote-jobs/job/" + (item.slug)),"target":"_blank"}},[_vm._v(" Open ")])],1):_c('span',[_vm._v("-")])]}},{key:"item.isApproved",fn:function(ref){
var item = ref.item;
return [(!item.isApproved)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("hourglass_bottom")]):_vm._e(),(!item.isApproved)?_c('div',{staticClass:"caption text--disabled"},[_vm._v(" Created "+_vm._s(_vm._f("fromNow")(item.createdAt))+" ")]):_vm._e(),(item.isApproved)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("verified")]):_vm._e()]}},{key:"item.isSuspended",fn:function(ref){
var item = ref.item;
return [(!item.isSuspended)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_vm._e(),(item.isSuspended)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("remove_circle")]):_vm._e()]}},{key:"item.isRenewCancelled",fn:function(ref){
var item = ref.item;
return [(!item.isRenewCancelled)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_vm._e(),(item.isRenewCancelled)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("remove_circle")]):_vm._e()]}}]),model:{value:(_vm.entities),callback:function ($$v) {_vm.entities=$$v},expression:"entities"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }