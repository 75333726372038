




















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'page-not-found',
  components: {},
})
export default class PageNotFound extends Vue {}
