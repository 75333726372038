import {
  IBlogArticle,
  IBlogArticleImageUpload,
  IBlogArticleUpdate,
} from '@/store/modules/BlogArticleModule';
import ApiService from './base/ApiService';
import { AxiosPromise } from 'axios';
import BaseApiService from './base/BaseApiService';
import FormData from 'form-data';

export default class BlogArticleService extends ApiService<
  IBlogArticle,
  IBlogArticleUpdate
> {
  constructor() {
    super('blog-articles');
  }

  async publishBlogArticle(
    id: number,
    publishAt?: Date | null,
  ): Promise<AxiosPromise<IBlogArticle>> {
    return BaseApiService.getInstance().post(
      `${this.namespace}/publish/${id}`,
      {
        publishAt: publishAt ? publishAt : new Date(),
      },
    );
  }

  async uploadImage(
    file: any,
    uploadData: IBlogArticleImageUpload,
  ): Promise<string> {
    const data = new FormData();
    data.append('upload', file, file.name);
    data.append('slug', uploadData.slug);
    data.append('title', uploadData.title);
    data.append('altText', uploadData.altText);

    if (uploadData.width) {
      data.append('resizeWidth', uploadData.width);
    }
    if (uploadData.height) {
      data.append('resizeHeight', uploadData.height);
    }
    if (uploadData.fitMode) {
      data.append('fitMode', uploadData.fitMode.toString());
    }
    if (uploadData.formats) {
      uploadData.formats.forEach((x) => {
        data.append('formats[]', x);
      });
    }

    const response = await BaseApiService.getInstance().post(
      `${this.namespace}/upload/`,
      data,
      {
        headers: {
          'accept': 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      },
    );

    if (response.status == 201) {
      return response.data;
    }
    return '';
  }
}
