import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Default from './layouts/Default.vue';
import VueMoment from 'vue-moment';

Vue.use(VueMoment);

Vue.config.productionTip = false;

Vue.component('default-layout', Default);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
