












































































































































import AuthHelper from '@/helpers/AuthHelper';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DefaultLayout extends Vue {
  clipped = false;
  drawer = true;
  fixed = false;
  items = [
    {
      icon: 'dashboard',
      title: 'Dashboard',
      to: '/',
    },
    {
      icon: 'work',
      title: 'Job Offers',
      to: '/job-offers',
    },
    {
      icon: 'article',
      title: 'Blog',
      to: '/blog-articles',
    },
    // {
    //   icon: 'article',
    //   title: 'Blog',
    //   subItems: [
    //     {
    //       icon: 'article',
    //       title: 'Articles',
    //       to: '/blog-articles',
    //     },
    //     {
    //       icon: 'note_add',
    //       title: 'Add Article',
    //       to: '/blog-articles/add',
    //     },
    //   ],
    // },
    {
      icon: 'people',
      title: 'Users',
      to: '/users',
    },
    {
      icon: 'business',
      title: 'Companies',
      to: '/companies',
    },
    {
      icon: 'mail',
      title: 'Subscriptions',
      to: '/subscriptions',
    },
    {
      icon: 'shopping_bag',
      title: 'Orders',
      to: '/orders',
    },
    {
      icon: 'assignment_late',
      title: 'Audits',
      to: '/audits',
    },
    {
      icon: 'update',
      title: 'Cron Jobs',
      to: '/cron-jobs',
    },
  ];
  miniVariant = false;
  right = true;
  rightDrawer = false;
  notifications = 4;
  title = 'Nomadswork';
  isDev = process.env.NODE_ENV === 'development';

  logout(): void {
    AuthHelper.logout();
    this.$router.push({ name: 'Login' });
  }
}
