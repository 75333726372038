import StatisticService from '@/services/api/StatisticService';
import {
  getModule,
  Module,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';
import store from '..';

export interface IJobOfferStatistic {
  total: number;
  newCreatedExternal: number;
  newCreatedInternal: number;
  suspended: number;
  highlighted: number;
  renewCancelled: number;
  fromRemoteOk: number;
  approved: number;
  notApproved: number;
  deleted: number;

  //last 7 days
  last7days_newCreated: number;
  last7days_newCreatedExternal: number;
  last7days_newCreatedInternal: number;
  last7days_suspended: number;
  last7days_highlighted: number;
  last7days_renewCancelled: number;
  last7days_fromRemoteOk: number;
  last7days_approved: number;
  last7days_notApproved: number;
  last7days_deleted: number;

  //last 30 days
  last30days_newCreated: number;
  last30days_newCreatedExternal: number;
  last30days_newCreatedInternal: number;
  last30days_suspended: number;
  last30days_highlighted: number;
  last30days_renewCancelled: number;
  last30days_fromRemoteOk: number;
  last30days_approved: number;
  last30days_notApproved: number;
  last30days_deleted: number;
}

export interface IStatisticState {
  jobOfferStatistic: IJobOfferStatistic | null;
}

@Module({ dynamic: true, store, name: 'statistic', namespaced: true })
export class StatisticModule extends VuexModule implements IStatisticState {
  jobOfferStatistic: IJobOfferStatistic | null = null;

  @MutationAction
  async loadJobOfferStatistic(): Promise<{
    jobOfferStatistic: IJobOfferStatistic;
  }> {
    const response = await StatisticService.getJobOfferStatistic();
    return {
      jobOfferStatistic: response.data,
    };
  }
}

export default getModule(StatisticModule, store);
