import {
  ISubscription,
  ISubscriptionUpdate,
} from '@/store/modules/SubscriptionModule';
import ApiService from './base/ApiService';

export default class SubscriptionService extends ApiService<
  ISubscription,
  ISubscriptionUpdate
> {
  constructor() {
    super('subscription');
  }
}
