import LocalStorageHelper, { StorageKeys } from './LocalStorageHelper';
import JWT from 'jwt-decode';
import AuthService from '@/services/api/AuthService';
import { IUserToken } from '@/store/modules/AuthModule';

export default class AuthHelper {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static hasAnyPermission(permissions: Permissions[]): boolean {
    //TODO: implement
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static hasAnyRole(appRoles: Roles[]): boolean {
    //TODO: implement
    return true;
  }

  static decodeAuthToken(token: string): IUserToken | undefined {
    if (!token) {
      return undefined;
    }
    return JWT<IUserToken>(token);
  }

  static getUserToken(): IUserToken | undefined {
    const stored_token = LocalStorageHelper.getItem(StorageKeys.token);
    if (!stored_token) {
      return undefined;
    }

    return AuthHelper.decodeAuthToken(stored_token);
  }

  static isAuthenticated(token?: IUserToken): boolean {
    if (!token) {
      token = AuthHelper.getUserToken();
    }
    if (!token) {
      return false;
    }
    if (!token.exp) {
      return false;
    }

    return token.exp > Date.now() / 1000;
  }

  static async tryRefreshToken(): Promise<boolean> {
    const refresh_token = LocalStorageHelper.getItem(StorageKeys.refresh_token);

    if (!refresh_token) {
      return false;
    }
    const result = await AuthService.refreshToken(refresh_token);
    if (result.status != 200 || !result || !result.data) {
      return false;
    }

    LocalStorageHelper.setItem(StorageKeys.token, result.data.access_token);

    return AuthHelper.isAuthenticated();
  }

  // static getRefreshInMilliseconds(tokenExp: number): number {
  //   const expiryDate = new Date(0);
  //   expiryDate.setUTCSeconds(tokenExp);

  //   const expiresInSec = Math.abs(
  //     (expiryDate.getTime() - new Date().getTime()) / 1000,
  //   );

  //   return (expiresInSec - 1) * 1000;
  // }

  static logout(): void {
    LocalStorageHelper.removeItem(StorageKeys.token);
    LocalStorageHelper.removeItem(StorageKeys.refresh_token);

    //TODO: invalidate the refresh token on SERVER side as well!
  }
}

export enum Roles {
  SystemAdmin,
}

export class Permissions {
  //UsersRead = "app:read:users";
}
