























































































































import { ModuleBase } from '@/store/ModuleBase';
import { Component, ModelSync, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class DataTableDialogs<
  IEntity extends { id: number; deletedAt?: Date },
  IUpdateEntity,
> extends Vue {
  @ModelSync('entities', 'change', { type: Array })
  dataItems!: IEntity[];

  entitiesData: IEntity[] = [];

  @Watch('entities')
  onEntitiesChanged(newValue: IEntity[]): void {
    this.entitiesData = newValue;
  }

  loadingTable = true;
  entityName = 'Entity';
  entityNamePl = 'Entities';
  loadFromParent = false;
  search = '';
  headers = [{ text: 'ID', value: 'id' }];
  rules = {
    required: (value: unknown): boolean | string => !!value || 'Required.',
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid e-mail.';
    },
  } as any;
  dialog = false;
  dialogDelete = false;
  editedIndex = -1;
  defaultItem = {} as IEntity;
  editedItem: IEntity = this.defaultItem;
  snackbar = {
    show: false,
    text: '',
    success: true,
  };
  module: ModuleBase<IEntity, IUpdateEntity> | undefined;

  showActions = true;
  showCreateButton = true;
  showExpand = false;

  groupBy: string | null = null;
  showGroupBy = false;

  expanded = [];

  get formTitle(): string {
    return this.editedIndex === -1
      ? `New ${this.entityName}`
      : `Edit ${this.entityName}`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    //this function has to be overritten;
  }

  addHeaders(
    headers: Array<{
      text: string;
      value: string;
      align?: string;
      filterable?: boolean;
      filter?: (item: IEntity) => boolean;
    }>,
  ): void {
    const newHeaders = [];
    const idHeader = this.headers[0];

    newHeaders.push(idHeader, ...headers);

    if (this.showActions) {
      const actionHeader = this.headers.find((x) => x.value == 'actions');
      if (actionHeader) {
        newHeaders.push(actionHeader);
      }
    }

    if (this.showExpand) {
      const expandHeader = this.headers.find(
        (x) => x.value == 'data-table-expand',
      );
      if (expandHeader) {
        newHeaders.push(expandHeader);
      }
    }

    this.headers = newHeaders;
  }

  async mounted(): Promise<void> {
    this.setup();

    if (this.showActions) {
      this.headers.push({ text: 'Actions', value: 'actions' });
    }
    if (this.showExpand) {
      this.headers.push({ text: '', value: 'data-table-expand' });
    }

    if (!this.loadFromParent) {
      await this.module?.loadAll();
      this.entitiesData = this.module?.entities || [];
    }

    this.loadingTable = false;
  }

  @Watch('dialogDelete')
  onDialogDeleteChanged(val: string): void {
    val || this.closeDelete();
  }

  rowClicked(item: IEntity, isExpanded: boolean): void {
    this.$emit('row-expanded-click', item, isExpanded);
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  closeDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  createButtonClick(): void {
    this.editedIndex = -1;
    this.editedItem = Object.assign({}, this.defaultItem);
    this.dialog = true;
  }

  editItem(item: IEntity): void {
    this.editedIndex = this.entitiesData.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  deleteItem(item: IEntity): void {
    this.editedIndex = this.entitiesData.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  deleteItemConfirm(): void {
    this.loadingTable = true;
    const subscription = this.entitiesData[this.editedIndex];
    this.module
      ?.delete(subscription.id)
      .then(() => {
        //this.entities.splice(this.editedIndex, 1);
        this.closeDelete();
        this.showSnackbar('Subscription deleted.');
      })
      .catch((error: unknown) => {
        console.error(error);
        this.showSnackbar('Error: Subscription could not be deleted!', false);
      })
      .finally(() => {
        this.loadingTable = false;
      });
  }

  save(): void {
    if (!this.editedItem) {
      this.close();
      return;
    }
    this.loadingTable = true;

    if (this.editedIndex > -1) {
      const itemToEdit = this.editedItem;
      const editIndex = this.editedIndex;

      this.module
        ?.update(this.editedItem)
        .then(() => {
          Object.assign(this.entitiesData[editIndex], itemToEdit);
          this.showSnackbar(`${this.entityName} edit was successful.`);
        })
        .catch((error: unknown) => {
          console.error(error);
          this.showSnackbar(
            `Error: ${this.entityName} could not be edited!`,
            false,
          );
        });
    } else {
      this.module
        ?.create(this.editedItem)
        .then(() => {
          if (this.editedItem) {
            this.showSnackbar(`${this.entityName} was created successfully.`);
          }
        })
        .catch((error: unknown) => {
          console.error(error);
          this.showSnackbar(
            `Error: ${this.entityName} could not be created!`,
            false,
          );
        });
    }
    this.close();
    this.loadingTable = false;
  }

  showSnackbar(text: string, success = true): void {
    this.snackbar.show = true;
    this.snackbar.text = text;
    this.snackbar.success = success;
  }

  itemRowBackground(item: IEntity): string {
    return item.deletedAt != null ? 'red lighten-5' : '';
  }
}
