































































import DataTableDialogs from '@/components/DataTable-Dialogs.vue';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import BlogArticleModule, {
  IBlogArticle,
  IBlogArticleUpdate,
} from '@/store/modules/BlogArticleModule';
import moment from 'moment';

@Component({
  components: {},
})
class BlogArticleDataTableDialogs extends Mixins<
  DataTableDialogs<IBlogArticle, IBlogArticleUpdate>
>(DataTableDialogs) {
  setup(): void {
    this.entityName = 'Blog Article';
    this.entityNamePl = 'Blog Articles';
    this.module = BlogArticleModule;
    this.showActions = true;
    this.showCreateButton = true;
    this.showExpand = false;

    this.addHeaders([
      { text: 'Image', value: 'imageTitle' },
      { text: 'Title', value: 'title' },
      { text: 'Published', value: 'isPublished' },
      { text: 'Published At', value: 'publishedAt' },
      { text: 'Category', value: 'mainCategory' },
      { text: 'Author', value: 'author' },
    ]);
  }

  //override
  createButtonClick(): void {
    this.$router.push({ name: 'Add Blog Article' });
  }

  //override
  editItem(item: IBlogArticle): void {
    this.$router.push({
      name: 'Edit Blog Article',
      params: { id: item.id.toString() },
    });
  }

  //override
  itemRowBackground(item: IBlogArticle): string {
    if (item.deletedAt != null) {
      return 'red lighten-5';
    }
    if (item.isRepublished) {
      return 'blue lighten-5';
    }
    return '';
  }
}

@Component({
  components: {
    BlogArticleDataTableDialogs,
  },
  filters: {
    fromNow(date?: Date) {
      if (!date) {
        return '';
      }
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - 7);
      if (date > newDate) {
        return moment(date).fromNow();
      } else {
        return moment(date).format('D MMM YY');
      }
    },
  },
})
export default class BlogArticles extends Vue {
  frontendUrl = process.env.VUE_APP_APP_URL || 'http://localhost:3000';

  getImageUrl(imageTitle: string, slug: string): string {
    return `${this.frontendUrl}/img/blog/posts/${slug}/${imageTitle}.webp`;
  }

  isArticleScheduled(article: IBlogArticle): boolean {
    if (!article.publishedAt) {
      return false;
    }
    return new Date(article.publishedAt) > new Date();
  }
}
